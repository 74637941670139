<!-- <app-submenu></app-submenu> -->
<app-header  *ngIf="!isEmpty(information) && !isEmpty(configuracion) "    [information]="information" [configuracion]="configuracion"  ></app-header>

<ngx-loading [show]="loadingAll" [config]="{ backdropBorderRadius: '3px',  fullScreenBackdrop: true}" [template]="">
</ngx-loading>

<section class="container-fluid pt-5">
    <div class="row pt-2 pb-2 pl-3 pr-3 ">
        <!-- Catalogo -->
        <!-- <div class="col-lg-3 order-lg-1 order-2 text-center mt-2" id="catalogo-carrito">
            <app-catalogo></app-catalogo>
        </div> -->

        <!-- Menu y Productos -->
        <div class="col-lg-12 order-lg-2 order-1">
            <!-- <app-menu></app-menu> -->
            <br>
            <div class="alert bg-light w-100 text-minuscula-texto-negro text-size-14" role="alert">
                <div class="row divSelect"  *ngIf="information.esPuntoVenta==1">
                    <div class="col-xxl-2 col-xl-3 col-lg-3 col-md-2 col-12  " type="button"  (click)="returnTO('catalogo')" >
                     <span class="fw-bold" > <i class="bi bi-arrow-left"></i>   Regresar  </span>   
                    </div>
                    <div class="col-xxl-3 col-xl-2 col-lg-3 col-md-2 col-12   text-center mt-1 mb-1 d-grid gap-2">
                            <button type="button" class="btn btn-sm btn-outline-dark rounded-pill btn-block" id="btn-cart" 
                            (click)="goProductsCatalogue()">
                            Continuar comprando
                        </button>
                    </div>
                    <div class="col-xxl-3 col-xl-2 col-lg-3 col-md-2 col-12  text-center mt-1 mb-1 d-grid gap-2">
                        <button type="button" class="btn btn-outline-danger btn-sm rounded-pill btn-block "
                        
                            (click)="modalDeleteFile(deleteFileModal, '', 'EmptyCart')">
                            Vaciar carrito
                            <i class="fas fa-cart-arrow-down ml-2"></i>
                        </button>
                    </div>
                    <div class="col-xxl-3 col-xl-2 col-lg-3 col-md-2 col-12  text-center mt-1 mb-1 d-grid gap-2" *ngIf="tipoCliente!=2">
                        <button *ngIf="information.esPuntoVenta==1" type="button" class="btn btn-sm btn-success rounded-pill btn-block "  (click)="calculatedValuesCrateOrder(1, addressDeliveryData)">
                            Pagar en Caja <i class="fas fa-store ml-2"></i>
                        </button>
                    </div>
                    <div class="col-xxl-3 col-xl-2 col-lg-3 col-md-2 col-12 text-center mt-1 mb-1 d-grid gap-2" *ngIf="tipoCliente==2">
                        <button type="button" class="btn btn-sm rounded-pill  btn-block btn-secondary "  (click)="modalInformationClient(informationClientModal)">
                            Envío a Domicilio  <i class="fa fa-home ml-2"></i>
                        </button>
                        <!-- <button type="button" class="btn btn-sm rounded-pill  btn-block btn-secondary "  (click)="modalInformationClient(informationClientModal)">
                            Envío a Domicilio 1 <i class="fa fa-home ml-2"></i>
                        </button> -->
                    </div>

                </div>
                <div class="row"  *ngIf="information.esPuntoVenta==0">
                    <div class="col-xxl-2 col-xl-3 col-lg-3 col-md-3 col-12  " type="button"  (click)="returnTO('catalogo')" >
                        <span class="fw-bold mt-2"  style="font-size: 16px;" > <i class="bi bi-arrow-left"></i>   Regresar  </span>   
                       </div>
                    <div class="col-lg-3  col-md-3  text-center mt-1 mb-1 d-grid gap-2">
                            <button type="button" class="btn btn-sm btn-outline-dark rounded-pill btn-block" id="btn-cart" 
                            (click)="goProductsCatalogue()">
                            Continuar comprando
                        </button>
                    </div>
                    <div class="col-lg-3 col-md-3  text-center mt-1 mb-1 d-grid gap-2">
                        <button type="button" class="btn btn-outline-danger btn-sm rounded-pill btn-block "
                        
                            (click)="modalDeleteFile(deleteFileModal, '', 'EmptyCart')">
                            Vaciar carrito
                            <i class="fas fa-cart-arrow-down ml-2"></i>
                        </button>
                    </div>
                    <div class="col-lg-3 col-md-3  text-center mt-1 mb-1 d-grid gap-2">
                        <button type="button" class="btn btn-sm rounded-pill  btn-block btn-secondary "  (click)="modalInformationClient(informationClientModal)">
                            Envío a Domicilio  <i class="fa fa-home ml-2"></i>
                        </button>
                    </div>
                </div>
            </div>

            <!-- Tabla de productos del carrito -->
            <div class="w-100 table-responsive">
                <table class="table table-hover">
                    <thead class="bg-light">
                        <tr class="text-size-20 text-mayuscula">
                            <th class="text-left" scope="col"></th>
                            <th class="text-left" scope="col">PRODUCTO</th>
                            <th class="text-left" scope="col">PRECIO</th>
                            <th class="text-center" scope="col">CANTIDAD</th>
                            <th class="text-left" scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let p of shoppingCart" class="text-size-12 text-minuscula-texto-negro">
                            <td class="text-center bg-light">
                                <img
                                *ngIf="p?.imagenPrincipal != '1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2'"
                                [src]="urlBase+p?.imagenPrincipal+'?alt=media'" class="mr-2"
                                style="max-width: 45px" >
                                <img
                                *ngIf="p?.imagenPrincipal == '1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2'"
                                src="../../../assets/img/imgDefault/sinProd.png" class="mr-2"
                                style="max-width: 25px" >
                                
                                <!-- <img
                                    src="https://drive.google.com/uc?export=view&id={{p.imagenPrincipal}}"
                                    style="max-width: 40px"> -->
                            </td>
                            <td class="text-left" *ngIf="!p.talla">
                                <span>{{p.pro_nom}}</span>
                                <p class="text-size-10 p-0 alert alert-light" *ngIf="p.guarnicion_descripcion"><span
                                        class="text-dark">Guarnición: </span> {{p.guarnicion_descripcion}}</p>
                                        <input type="text" class="form-control form-control-sm rounded-1" placeholder="Ingrese detalle" (input)="changeDetalle($event, p)"  [id]="p.pro_nom"  >
                                </td>
                            <td class="text-left" *ngIf="p.talla">
                                <span class="w-100">{{p.pro_nom}} </span>
                                <p class="text-size-10 pt-1" *ngIf="p.talla">
                                    <span>{{p.talla}}</span>
                                    <span *ngIf="p.color"> COLOR {{p.color}}</span>
                                </p>
                            </td>
                            <td class="text-left">$ {{p.precioReal | number: '1.2'}}</td>
                            <td class="text-center">
                                <!-- <span>
                                    <button class="btnQuit"
                                        style="border: none;  font-size: 22px; " (click)="quantityProduct('quit', p)">
                                        <i class="bi bi-dash"></i>
                                    </button>
                                </span>
                                <span style="margin: 0px 5px; font-size: 16px; ">
                                    {{p.quantity}}
                                </span>
                                <span>
                                    <button class="btnAdd" style="border: none; font-size: 22px; "
                                    (click)="quantityProduct('add', p)">
                                        <i class="bi bi-plus"></i>
                                    </button>
                                </span> -->
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr class="text-center text-minuscula-texto">
                                            <th (click)="quantityProduct('quit', p)"><i
                                                    class="fas fa-minus icn-qty"></i></th>
                                            <td>{{p.quantity}}</td>
                                            <td (click)="quantityProduct('add', p)"><i
                                                    class="fas fa-plus text-size-10 icn-qty"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td class="text-center" (click)="modalDeleteFile(deleteFileModal, p, 'Product')">
                                <button class="btn-danger btn btn-sm  " style="border: none;"  >
                                    <i class="far fa-trash-alt  text-size-16  "></i>

                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="w-100 text-mayuscula text-size-14">
                <div class="row p-3">
                    <div class="col-lg-12 ">
                        <div class="row p-4 border rounded bg-light  ">
                            <div class="col-lg-6 ">
                                <h5><strong>SUBTOTAL</strong></h5>
                            </div>
                            <div class="col-lg-6 ">
                                <h5><strong id="th-total">${{totalCart | number : '1.2'}}</strong></h5>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 mt-3 "  *ngIf="information.esPuntoVenta==0">
                        <div class="row border rounded pt-3 pb-3">
                            <div class="col-lg-2"></div>
                            <div class="col-lg-4 col-md-6 d-grid gap-2">
                                <button  type="button" class="btn btn-sm btn-success rounded-pill" (click)="calculatedValuesCrateOrder(1, addressDeliveryData)">
                                    {{configuracion.txtBtnEnviarPedido1}} <i class="fas fa-store ml-2"></i>
                                </button>

                            </div>
                            <div class="col-lg-4 col-md-6 d-grid gap-2 pt-1">
                                <button type="button" class="btn btn-sm rounded-pill btn-secondary " (click)="modalInformationClient(informationClientModal)">
                                    Envío a Domicilio  <i class="fa fa-home ml-2"></i>
                                </button>
                            </div>
                            <div class="col-lg-2"></div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
        </div>
    </div>
</section>

<!-- <section class="container-fluid">
    <app-menu-responsivo></app-menu-responsivo>
</section> -->

<!-- Alerta para eliminar un archivo -->
<ng-template #deleteFileModal let-modal>
    <div class="row pt-3 tipo-letra text-size-12">
        <div class="col-12 text-size-13 text-center pt-3" role="alert">
            <span class="alert alert-danger pl-5 pr-5 rounded-pill">¿Seguro que desea eliminar?</span>
        </div>
        <div class="col-12 text-center text-size-13 pt-4">
            <p class="pb-0">{{messageModal.description}}</p>
        </div>
    </div>

    <div class="row modal-body tipo-letra text-center pt-0">
        <div class="col-12 text-center">
            <button type="button" class="btn btn-outline-success text-center mr-3 round" (click)="modal.close()">
                <i class="fas fa-check-circle"></i>
            </button>
            <button type="button" class="btn btn-outline-danger text-center" (click)="modal.dismiss('Cross click')">
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
</ng-template>

<!-- Alerta para validar si el cliente tiene cuenta payphone -->
<ng-template #ModalPayPhone let-modal>

    <div class="card row text-minuscula">

        <div class="col-lg-12 text-center mt-3">
            <div class="row">
                <div class="col-10 text-size-16 text-secondary">
                    <span>Ingrese su número de teléfono</span>
                </div>
                <div class="col-2"><i class="far fa-times-circle text-danger"
                        (click)="modal.dismiss('Cross click')"></i></div>
            </div>
        </div>

        <br>

        <form (submit)="modal.close('aceptar')">
            <div class="col-lg-12">
                <div class="form-group text-center">
                    <input class="form-control form-control-sm" ng2TelInput
                        [ng2TelInputOptions]="{initialCountry: 'ec'}" (hasError)="hasError($event)"
                        (ng2TelOutput)="getNumber($event)" (intlTelInputObject)="telInputObject($event)"
                        (countryChange)="onCountryChange($event)" [(ngModel)]="payPhoneAccount.number"
                        [ngModelOptions]="{standalone: true}" />
                </div>
            </div>

            <div class="col-lg-12 text-center">
                <p class="text-size-13">
                    Este pago está siendo procesado de forma segura por PayPhone, un proveedor de Grupo Promerica.
                </p>
                <img class="w-100" src="../../../assets/img/botones/payphone.jpg" alt="">
            </div>

            <hr>
        </form>

        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-3"> </div>

                <div class="col-lg-6">
                    <button type="button" class="btn btn-outline-dark text-size-14 btn-sm btn-block"
                        (click)="modal.close('aceptar')" id="btn-valid-pphone">
                        Validar
                    </button>
                </div>

                <div class="col-lg-3"> </div>
            </div>
        </div>

        <br>

    </div>

</ng-template>

<!-- Alerta para indicar como crear cuenta y registrarse en payphone -->
<ng-template #ModalTutorialPayPhone let-modal>
    <div class="card row text-minuscula">

        <br>

        <div class="col-lg-12 text-center">
            <p class="text-size-14">
                Su número de telefono no se encuentra registrado en PayPhone, instale la aplicación y registrese para
                poder procesar su pago.
            </p>
        </div>

        <hr>

        <div class="col-lg-12 text-center">
            <p class="text-left text-size-13">1. Intalar aplicación</p>
            <iframe class="w-100" src="https://www.youtube.com/embed/8ikeKLsYVDc" title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
        </div>

        <div class="col-lg-12 text-center">
            <p class="text-left text-size-13"> 2. Registrarse</p>
            <iframe class="w-100" src="https://www.youtube.com/embed/lWca5gTX4Cc" title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
        </div>

        <hr>

        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-3"> </div>

                <div class="col-lg-6">
                    <button type="button" class="btn btn-outline-dark text-size-14 btn-sm btn-block"
                        (click)="modal.close('aceptar')">
                        Entendido
                    </button>
                </div>

                <div class="col-lg-3"> </div>
            </div>
        </div>

        <br>

    </div>
</ng-template>

<!-- Mostrar spinner mientras se concreta la compra con payphone -->
<ng-template #ModalWaitTransactionPayphone let-modal>

    <div class="card row text-minuscula">

        <br>

        <div class="col-lg-12 text-center">
            <p class="text-size-14">
                Espere mientras se procesa el pago . . .
            </p>
            <div class="progress">
                <div class="progress-bar progress-bar-striped progress-bar-animated bg-orange" role="progressbar"
                    aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
            </div>
            <p class="text-size-14 mt-3">
                Abra su aplicación de PayPhone para concretar su compra
            </p>
        </div>

        <hr>

        <div class="col-lg-12">
            <button type="button" class="btn btn-outline-danger text-size-14 btn-sm btn-block"
                (click)="modal.close('Cancel')">Cancelar Transacción</button>
        </div>

        <br>

    </div>

</ng-template>

<!-- Mostrar resultado de la transaccion -->
<ng-template #ModalTransactionResult let-modal>
    <div class="card row text-minuscula">
        <br>
        <div class="col-lg-12 text-center">
            <h2><i class="fas fa-thumbs-up text-size-20 text-success" *ngIf="transaction.status == 'Accepted'"></i></h2>
            <h2><i class="fas fa-thumbs-down text-size-20 text-warning" *ngIf="transaction.status == 'Rejected'"></i>
            </h2>
            <h2><i class="fas fa-ban text-size-20 text-danger" *ngIf="transaction.status == 'Canceled'"></i></h2>
        </div>

        <br>
        <div >
            <div class="col-lg-12 text-center">
                <p class="text-size-14"  *ngIf="information.esPuntoVenta==0">
                    {{transaction.result}}
                </p>
                <p class="text-size-14 text-center "  *ngIf="information.esPuntoVenta==1">
                     El pago se ha realizado con éxito, proceda a generar el pedido
                </p>
            </div>
    
            <div class="col-lg-12 text-center" *ngIf="transaction.reference != 0 && information.esPuntoVenta!=1">
                <p class="text-size-14 border rounded" *ngIf="information.esPuntoVenta==0">
                    Número Ref.
                    <span class="ml-2"><strong class="text-size-16">{{transaction.reference}}</strong></span>
                </p>
                <p class="text-size-14 border rounded" *ngIf="information.esPuntoVenta==1">
                    Número Pedido
                    <span class="ml-2"><strong class="text-size-16">{{transaction.reference}}</strong></span>
                </p>
                <div class="col-12" *ngIf="information.esPuntoVenta==1" >
                        <table class="table table-bordered">
                            <thead class="thead-ligth">
                              <tr>
                                <th scope="col">Nombre</th>
                                <th scope="col">USD</th>
                                <th scope="col">Cant</th>
                                <th scope="col">PT</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let p of shoppingCart" >
                                <td>{{p.pro_nom}}</td>
                                <td>$ {{p.precioReal | number: '1.2'}}</td>
                                <td>{{p.quantity}}</td>
                                <td>{{p.precioReal*p.quantity | number: '1.2'}}</td>
                              </tr>
                            </tbody>
                        </table>
                            <div class="col-lg-12">
                                <div class="row p-2 border rounded bg-light">
                                    <div class="col-lg-6 text-right">
                                        <h6><strong>SUBTOTAL</strong></h6>
                                    </div>
                                    <div class="col-lg-6">
                                        <h6><strong id="th-total">${{totalCart | number : '1.2'}}</strong></h6>
                                    </div>
                                </div>
                            </div>
        
                </div>
    
                <p class="text-size-14">
                    Gracias por su compra.
                </p>
            </div>
    
            <br>
        </div>

      
        <div class="col-lg-12 mb-3">
            <div class="row" *ngIf="information.esPuntoVenta==0">
                <div class="col-lg-3"> </div>

                <div class="col-lg-6">
                    <button type="button" class="btn text-size-14 btn-sm btn-success btn-block" (click)="modal.close('')"
                        id="btn-result-ok">ok</button>
                </div>

                <div class="col-lg-3"> </div>
            </div>
            <div class="row" *ngIf="information.esPuntoVenta==1">
                <div class="col-lg-3"> </div>

                <!-- <div class="col-lg-4">
                    <button type="button" class="btn text-size-14 btn-sm btn-secondary btn-block" (click)="modal.close('')"
                        id="btn-result-ok">Salir</button>
                </div> -->
                <!-- <div class="col-lg-6">
                    <button type="button" class="btn text-size-14 btn-sm btn-success btn-block"  printSectionId="ticket" ngxPrint (click)="modal.close('')"
                        id="btn-result-ok">Imprimir</button>
                </div> -->
                <div class="col-lg-6">
                    <button type="button" class="btn text-size-14 btn-sm btn-success btn-block"    (click)="abrirComanda(addressDeliveryData.client)"
                        id="btn-result-ok">Hacer pedido</button>
                </div>

                <div class="col-lg-3"> </div>
            </div>
        </div>
        <br>
        <div id="ticket" #content style="display: none;" >
            <p class=" " style="text-align: center;  font-size: 9px"  *ngIf="information.esPuntoVenta==1">
                El pedido se ha generado con éxito, imprima el ticket y presente en caja
            </p>
            <p class=""  style="text-align: center;  font-size: 9px" *ngIf="information.esPuntoVenta==1">
                Número Pedido
                <span class="ml-2"><strong class="text-size-16">{{transaction.reference}}</strong></span>
            </p>

            <p style="text-align: center; font-weight: bold; "  >
                Detalle del Pedido
            </p>
            <div style="width: 100%;margin-top: 7px;">
                <table class="" style="width: 100%; border-collapse: collapse; font-size: 8px;">
                    <tr style="text-align: center">
                        <th>Cant</th>
                        <th>Descripción</th>
                        <th>P.U</th>
                        <th>P.T</th>
                      </tr>
                      <tr style="text-align: center;font-size: 8px;" *ngFor="let prod of shoppingCart">
                        <td>
                          {{prod.quantity}}
                        </td>

                        <td >
                          {{prod.pro_nom}}
                        </td>
                        <td>
                          {{prod.quantity |currency:'':'':'1.2-2'}}
                        </td>
                        <td>
                          {{prod.precioReal *prod.quantity |currency:'':'':'1.2-2'}}
                        </td>
            
                      </tr>
                </table>
            </div>
            <div style="width: 100% ; margin-top: 1em  ;">
                <table class="" style="width: 100%; border-collapse: collapse; font-size: 8px;">           
                  <tr style="text-align: right;">
                    <td><span style="font-weight: bold;" >SUBTOTAL: $ {{totalCart |currency:'':'':'1.2-2' }}</span></td>
                  </tr>
                </table>
              </div>
              <p class="text-size-14" style="text-align: center;  font-size: 9px" >
                Gracias por su compra.
            </p>


        </div>
    </div>
</ng-template>

<!-- Mostrar DataFast -->
<ng-template #ModalDatafast let-modal>

    <div class="card row text-minuscula bg-datafast">

        <br>

        <div class="col-lg-12 text-center pt-3">
            <img class="w-75 mb-4" src="https://www.datafast.com.ec/images/verified.png" alt="">
            <form id="formDataFast" action="{{dataFast.dominio}}" class="paymentWidgets"
                data-brands="VISA MASTER AMEX DINERS DISCOVER">
            </form>
        </div>

        <br>

        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-3"> </div>

                <div class="col-lg-6">
                    <button type="button" class="btn btn-danger text-size-14 btn-sm btn-block"
                        (click)="modal.dismiss('Cancel')">Cancelar Transacción</button>
                </div>

                <div class="col-lg-3"> </div>
            </div>
        </div>

        <br>

    </div>

</ng-template>

<ng-template #paypalModal let-modal>
    <div class="card modal-pago-paypal">
        <div class="row p-3 tipo-letra">
            <div class="col-2 float-left">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>

        <div class="modal-body tipo-letra">
            <ngx-paypal [config]="payPalConfig"></ngx-paypal>
        </div>
    </div>
</ng-template>

<!-- *** Nuevo proceso compra *** -->

<!-- Completar información del cliente -->
<ng-template #informationClientModal let-modal>
    <div class="row text-minuscula pl-2 pr-2 pb-4 bg-light">
        <!-- Mensaje Cabecera -->
        <div class="col-lg-12 mt-3">
            <div class="row" *ngIf="!addressDeliveryData.btnShop">
                <div class="col-12 text-size-14 alert alert-secondary pt-2 pb-2 border-dan envger">
                    <span class="float-left"><i class="fa fa-user-times mr-2 text-danger"></i>Complete su
                        información</span>
                    <span class="float-right"><i class="far fa-times-circle text-danger"
                            (click)="modal.dismiss('Cross click')"></i></span>
                </div>
            </div>
            <div class="row" *ngIf="addressDeliveryData.btnShop">
                <div class="col-12 text-size-14 alert alert-success pt-2 pb-2 border-success rounded-pill">
                    <span class="float-left rounded-pill"><i class="fas fa-home mr-2"></i>Datos de envío a domicilio</span>
                    <span class="float-right"><i class="far fa-times-circle text-danger"
                            (click)="modal.dismiss('Cross click')"></i></span>
                </div>
            </div>
        </div>
        <!-- Direcciones -->
        <div class="col-12 text-center" *ngIf="addressDeliveryData.btnShop">
            <div class="btn-group btn-group-sm" role="group" aria-label="Basic example">
                <button type="button" class="btn btn-outline-dark btn-sm rounded-1" (click)="selectAddressDelivery()">Mi
                    dirección<i class="fa fa-check-circle ml-1 text-success" aria-hidden="true"
                        *ngIf="addressDeliveryData.myLocation"></i></button>
                <button type="button" class="btn btn-outline-secondary btn-sm rounded-1" (click)="selectAddressDelivery()">Otra
                    dirección<i class="fa fa-check-circle ml-1 text-success" aria-hidden="true"
                        *ngIf="!addressDeliveryData.myLocation"></i></button>
            </div>
        </div>
        <!-- Linea horizontal -->
        <div class="col-lg-12">
            <hr>
        </div>
        <!-- Formulario -->
        <div class="col-lg-12">
            <!-- Nombres -->
            <div class="form-group text-size-13">
                <label>
                    <span *ngIf="addressDeliveryData.emptyAttributes.nombres" class="text-dark"><i
                            class="fas fa-id-card-alt mr-2"></i>Nombres</span>
                    <span *ngIf="!addressDeliveryData.emptyAttributes.nombres" class="text-danger"><i
                            class="fas fa-id-card-alt mr-2"></i>Nombres</span>
                    <span class="text-danger ml-1">*</span>
                </label>
                <input type="text" class="form-control form-control-sm rounded-pill" name="cedula"
                    [(ngModel)]="addressDeliveryData?.infoSend.nombres">
            </div>
            <!-- Apellidos -->
            <div class="form-group text-size-13">
                <label>
                    <span *ngIf="addressDeliveryData.emptyAttributes.apellidos" class="text-dark"><i
                            class="fas fa-id-card-alt mr-2"></i>Apellidos</span>
                    <span *ngIf="!addressDeliveryData.emptyAttributes.apellidos" class="text-danger"><i
                            class="fas fa-id-card-alt mr-2"></i>Apellidos</span>
                    <span class="text-danger ml-1">*</span>
                </label>
                <input type="text" class="form-control form-control-sm rounded-pill" name="cedula"
                    [(ngModel)]="addressDeliveryData?.infoSend.apellidos">
            </div>
            <!-- Provincia -->
            <div class="form-group text-size-13">
                <label>
                    <span *ngIf="addressDeliveryData.emptyAttributes.provincia" class="text-dark"><i
                            class="fas fa-map-marked-alt mr-2"></i>Provincia</span>
                    <span *ngIf="!addressDeliveryData.emptyAttributes.provincia" class="text-danger"><i
                            class="fas fa-map-marked-alt mr-2"></i>Provincia</span>
                    <span class="text-danger ml-1">*</span>
                </label>
                <select class="form-select border form-select-sm w-100 pl-2 pt-1 pb-1 rounded-pill"
                    (change)="addProvinceClient(addressDeliveryData.infoSend.provincia)"
                    [(ngModel)]="addressDeliveryData.infoSend.provincia">
                    <option class="text-size-12" *ngFor="let p of addressDeliveryData.province" [selected]="p.selected"
                        value="{{p.idProvincia}}"> {{p.descripProv}}</option>
                </select>
            </div>
            <!-- Canton -->
            <div class="form-group text-size-13">
                <label>
                    <span *ngIf="addressDeliveryData.emptyAttributes.canton" class="text-dark"><i
                            class="fa fa-map-marker mr-2"></i>Cantón</span>
                    <span *ngIf="!addressDeliveryData.emptyAttributes.canton" class="text-danger"><i
                            class="fa fa-map-marker mr-2"></i>Cantón</span>
                    <span class="text-danger ml-1">*</span>
                </label>
                <select class="form-select border form-select-sm w-100 pl-2 pt-1 pb-1 rounded-pill"
                    [(ngModel)]="addressDeliveryData.infoSend.canton">
                    <option class="text-size-12" *ngFor="let c of addressDeliveryData.canton" [selected]="c.selected"
                        value="{{c.idCanton}}"> {{c.descripCtn}}</option>
                </select>
            </div>
            <!-- Direccion -->
            <div class="form-group text-size-13">
                <label>
                    <span *ngIf="addressDeliveryData.emptyAttributes.direccion" class="text-dark"><i
                            class="fa fa-address-card mr-2"></i>Dirección</span>
                    <span *ngIf="!addressDeliveryData.emptyAttributes.direccion" class="text-danger"><i
                            class="fa fa-address-card mr-2"></i>Dirección</span>
                    <span class="text-danger ml-1">*</span>
                </label>
                <input type="text" class="form-control form-control-sm rounded-pill" name="email"
                    [(ngModel)]="addressDeliveryData?.infoSend.direccion">
            </div>
            <!-- Referencia -->
            <div class="form-group text-size-13">
                <label>
                    <span *ngIf="addressDeliveryData.emptyAttributes.referencia_domicilio" class="text-dark"><i
                            class="fa fa-info-circle mr-2"></i>Referencia</span>
                    <span *ngIf="!addressDeliveryData.emptyAttributes.referencia_domicilio" class="text-danger"><i
                            class="fa fa-info-circle mr-2"></i>Referencia</span>
                    <span class="text-danger ml-1">*</span>
                </label>
                <input type="text" class="form-control form-control-sm rounded-pill" name="email"
                    [(ngModel)]="addressDeliveryData?.infoSend.referencia_domicilio">
            </div>
            <!-- Celular -->
            <div class="form-group text-size-13">
                <label>
                    <span *ngIf="addressDeliveryData.emptyAttributes.celular" class="text-dark"><i
                            class="fa fa-phone-square mr-2"></i>Celular</span>
                    <span *ngIf="!addressDeliveryData.emptyAttributes.celular" class="text-danger"><i
                            class="fa fa-phone-square mr-2"></i>Celular</span>
                    <span class="text-danger ml-1">*</span>
                </label>
                <input type="text" class="form-control form-control-sm rounded-pill" name="celular"
                    [(ngModel)]="addressDeliveryData?.infoSend.celular">
            </div>
            <!-- <br> -->
            <button type="button" class="btn btn-secondary text-size-14 btn-sm btn-block rounded-pill" id="btn-update"
                (click)="modal.close('update')" *ngIf="!addressDeliveryData.btnShop">
                Actualizar <i class="fa fa-refresh ml-1" aria-hidden="true"></i>
            </button>
            <!-- <button *ngIf="information.esPuntoVenta==1 && addressDeliveryData.btnShop"  type="button" class="btn btn-success text-size-14 btn-sm btn-block rounded-pill" id="btn-update"
               (click)="abrirComanda(addressDeliveryData.client)" >
                Continuar 1 
            </button>  -->
            <!-- <button *ngIf="information.esPuntoVenta!=1 && addressDeliveryData.btnShop"  type="button" class="btn btn-success text-size-14 btn-sm btn-block rounded-pill" id="btn-update" -->
            <button *ngIf="addressDeliveryData.btnShop"  type="button" class="btn btn-success text-size-14 btn-sm btn-block rounded-pill" id="btn-update"
                (click)="modal.close('shop')" >
                Continuar  
            </button> 
        </div>
        <br>
    </div>
</ng-template>

<!-- Mostrar Botones de pago -->
<ng-template #showPaymentButtonsModal let-modal>
    <div class="row text-minuscula pl-2 pr-2 pb-4 bg-light">
        <!-- Mensaje Cabecera -->
        <div class="col-lg-12 mt-3">
            <div class="row">
                <!-- <div class="col-12 text-size-14 pt-2 pb-2 rounded border rounded-pill" id="div-header-pay"> -->
                <div class="col-12 text-size-14 pt-2 pb-2 rounded border rounded-pill divFormasPago " >
                    <span class="float-left"><i class="fa fa-money mr-2"></i>Seleccione la forma de pago</span>
                    <span class="float-right"><i class="far fa-times-circle"
                            (click)="modal.dismiss('Cross click')"></i></span>
                </div>
            </div>
        </div>
        <!-- Botones de pago -->
        <div class="col-lg-12 mt-3 text-minuscula-texto-negro">
            <button type="button" class="btn btn-outline-dark text-size-20 btn-sm btn-block mb-3 rounded-pill"
                *ngIf="buttonVisibility.domicilio" (click)="modal.close(2)">
                <span class="float-left">Pago en la entrega</span><i class="fa fa-handshake-o float-right text-size-20"
                    aria-hidden="true"></i>
            </button>
            <button type="button" class="btn btn-outline-dark text-size-20 btn-sm btn-block mb-3 rounded-pill btn-trans-qr"
                *ngIf="buttonVisibility.pagoQR" (click)="modal.close(9)">
                <span class="float-left"> Pago con código QR </span> <i class="fa fa-qrcode float-right text-size-20" aria-hidden="true"></i> 
                <!-- <i class="fa fa-handshake-o float-right text-size-20"aria-hidden="true"></i> -->
            </button>

            <button type="button" class="btn btn-outline-dark text-size-20 btn-sm btn-block mb-3 btn-trans-bnc rounded-pill"
                *ngIf="buttonVisibility.tranferencia" (click)="modal.close(4)">
                <span class="float-left">Transferencia Bancaría</span><i class="fa fa-refresh float-right text-size-20"
                    aria-hidden="true"></i>
            </button>

            <button type="button" class="btn btn-outline-dark text-size-20 btn-sm btn-block mb-3 btn-paypal rounded-pill"
                *ngIf="buttonVisibility.paypal" (click)="modal.close(8)">
                <span class="float-left">PayPal</span><i class="fa fa-paypal float-right text-size-20"
                    aria-hidden="true"></i>
            </button>

            <button type="button" class="btn btn-outline-dark text-size-20 btn-sm btn-block mb-3 btn-datafast rounded-pill"
                *ngIf="buttonVisibility.datafast" (click)="modal.close(6)">
                <span class="float-left">DataFast</span><img src="../../../assets/icons/datafast.png"
                    class="float-right" width="8%" alt="">
            </button>

            <button type="button" class="btn btn-outline-dark text-size-20 btn-sm btn-block mb-3 btn-payphone rounded-pill"
                *ngIf="buttonVisibility.payphone" (click)="modal.close(5)">
                <span class="float-left">PayPhone</span><img src="../../../assets/icons/payphone.png"
                    class="float-right" width="8%" alt="">
            </button>

            <button type="button" class="btn btn-outline-success text-size-20 btn-sm btn-block mb-3 btn-whatsapp rounded-pill"
                *ngIf="buttonVisibility.whatsapp" (click)="modal.close(3)">
                <span class="float-left">WhatsApp</span><i class="fa fa-whatsapp float-right text-success text-size-20"
                    aria-hidden="true"></i>
            </button>
            <!-- <button type="button" class="btn btn-outline-success text-size-20 btn-sm btn-block mb-3 btn-whatsapp rounded-pill"
                *ngIf="buttonVisibility.whatsapp" (click)="modal.close(3)">
                <span class="float-left">WhatsApp</span><i class="fa fa-whatsapp float-right text-success text-size-20"
                    aria-hidden="true"></i>
            </button> -->
        </div>
        <br>
    </div>
</ng-template>

<!-- Alerta para mostrar los valores totales de la compra -->
<ng-template #ModalDatailValuesShop let-modal>
    <div class="row text-minuscula pl-2 pr-2 pb-4 bg-light text-size-16">
        <!-- Mensaje Cabecera -->
        <div class="col-lg-12 mt-3">
            <div class="row">
                <div class="col-12 pt-2 pb-2 rounded border" id="div-header-values">
                    <span class="float-left text-white "><i class="fa fa-usd mr-2"></i>DETALLES VALORES DEL PEDIDO</span>
                    <span class="float-right"><i class="far fa-times-circle text-danger"
                            (click)="modal.dismiss('Cross click')"></i></span>
                </div>
            </div>
        </div>
        <!-- Detale de valores -->
        <div class="col-lg-12 mt-3">
            <!-- Datos de la cuenta Bancaria -->
            <div class="form-group text-center text-size-14 alert div-cta-bancaria border-secondary rounded" role="alert"
                *ngIf="bankAccountData.view" style="background-color: #f8f8c0;">
                <p class="sin-espacios">{{bankAccountData.data.banco}}</p>
                <p class="sin-espacios">{{bankAccountData.data.nombre}}</p>
                <p class="sin-espacios">{{bankAccountData.data.numero}}</p>
                <p class="sin-espacios">{{bankAccountData.data.ci_ruc}}</p>
                <p class="sin-espacios">{{bankAccountData.data.tipo_cuenta}}</p>
            </div>
            <!-- Img QR codigo PAGO -->
            <div class="row pb-2"   *ngIf="qrAccountData.view" >
                <div class="col-12">
                    <div class="card">
                        <img class="mb-2" id="imgQR" *ngIf="qrAccountData.data.qr1" [src]="urlBase+qrAccountData.data.qr1+'?alt=media'" alt="">
                        <img class="mb-2"  id="imgQR"  *ngIf="qrAccountData.data.qr2" [src]="urlBase+qrAccountData.data.qr2+'?alt=media'" alt="">
                        <img class="mb-2"  id="imgQR"  *ngIf="qrAccountData.data.qr3" [src]="urlBase+qrAccountData.data.qr3+'?alt=media'" alt="">
                    </div>
                    </div>
                  
            </div>
            <div class="pb-3 text-center"  *ngIf="bankAccountData.view || qrAccountData.view">
                <div class="file-input-container">
                    <input type="file" accept="image/*" class="file-input" id="fileInput" (change)="onSelect($event)">
                    <label for="fileInput" class="file-input-label"> Subir Comprobante <i class="fa fa-cloud-upload pl-2" aria-hidden="true"></i>
                    </label>
                  </div>
                  <span *ngIf="flagComprobante"  for="">{{comprobante.name}}</span>
                  <br>
                  <span *ngIf="flagComprobante" class="bg-success text-white  fw-bold py-1 px-2 rounded-pill"  style="font-size: 13px;" >Archivo listo para subirse <i class="fa fa-check" aria-hidden="true"></i>
                  </span>
            </div>
            <!-- Detalle de los valores de la compra -->
            <div class="form-group text-left">
                <table class="table table-striped table-sm border rounded">
                    <tbody>
                        <tr>
                            <td>Subtotal:</td>
                            <td>{{valuesOrder.calculos.subtotal | number : '1.2-2'}}</td>
                        </tr>
                        <tr *ngIf="valuesOrder.descuento.rta">
                            <td>Descuento (% {{valuesOrder.descuento.data.porcent}}):</td>
                            <td>{{valuesOrder.calculos.descuento | number : '1.2-2'}}</td>
                        </tr>
                        <tr *ngIf="valuesOrder.recargo.rta">
                            <td>{{valuesOrder.recargo.data.message}}</td>
                            <td>{{valuesOrder.calculos.recargo | number : '1.2-2'}}</td>
                        </tr>
                        <tr>
                            <td>IVA
                                <span class="text-size-13">( {{valuesOrder.calculos.ivaPorcent}} % ) :</span>
                            </td>
                            <td>{{valuesOrder.calculos.iva | number : '1.2-2'}}</td>
                        </tr>
                        <tr *ngIf="valuesOrder.costoEnvio.rta">
                            <td> Costo de envío estimado
                                <p class="text-size-13" *ngIf="valuesOrder.costoEnvio.data.nacional.selected">
                                    ( {{valuesOrder.costoEnvio.data.nacional.provincia}}, {{
                                    valuesOrder.costoEnvio.data.nacional.canton}} )
                                </p>
                            </td>
                            <td *ngIf="valuesOrder.costoEnvio.data.local.selected">{{valuesOrder.costoEnvio.data.local.value | number : '1.2-2'}}</td>
                            <td *ngIf="valuesOrder.costoEnvio.data.nacional.selected">{{valuesOrder.costoEnvio.data.nacional.value | number : '1.2-2'}}</td>
                        </tr>
                        <tr *ngIf="valuesOrder.costoEnvio.rta">
                            <td><strong>Total a pagar:</strong></td>
                            <td>{{valuesOrder.subtotalAux | number : '1.2-2'}}</td>
                        </tr>
                        <tr *ngIf="!valuesOrder.costoEnvio.rta">
                            <td><strong>Total:</strong></td>
                            <td>{{valuesOrder.calculos.total | number : '1.2-2'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- Aceptar valores de la compra -->
            <div class="alert alert-danger text-size-13 text-center" role="alert">
                Esta de acuerdo con el total a pagar, para proceder con la compra
            </div>
            <!-- Botones de aceptar y cancelar -->
            <hr>
            <div class="row">
                <div class="col-lg-6 text-center">
                    <button type="button" class="btn btn-outline-success btn-sm btn-block" (click)="modal.close('aceptar')">
                        Aceptar
                        <!-- <i class="fa fa-check-circle-o mr-1" aria-hidden="true"></i> -->
                    </button>
                </div>
                <div class="col-lg-6 text-center">
                    <button type="button" class="btn btn-outline-danger btn-sm btn-block" (click)="modal.dismiss('cancelar')">
                        Cancelar
                        <!-- <i class="fa fa-times-circle-o mr-1     ssss" aria-hidden="true"></i> -->
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- *** Fin Nuevo proceso compra *** -->


<app-scroll-to-top></app-scroll-to-top>

<div *ngIf="this.information " >
    <!-- <app-footer [configuracion]="configuracion" [informacion]="information" ></app-footer> -->

</div>

<ng-template #modalCarritoLocal class="modal fade" data-backdrop="static" data-keyboard="false">
    <div class="modal-header " style="text-align: center !important;">

        <div class="modal-body">
            <div class="col-lg-12 text-center mb-3" style="position: relative;">
                <div class="row">
                    <div class="col-12 text-size-14 text-size-16 text-secondary" style="font-weight: bold;">
                        DETALLE ORDEN PEDIDO
                    </div>
                </div>
                <i class="bi bi-x-lg icoCarLocal"
                    style="position: absolute;top: 0; right: 0; font-size: 16px; font-weight: bold;  "
                    (click)="closeModal()"></i>
            </div>
            <div class="container">
                <div >
                    <hr>
                    <div class="col-12 text-size-14 text-size-16 text-secondary" style="font-weight: bold;">
                        NOMBRE DE QUIEN RETIRA EL PEDIDO
                    </div>
                    <div class="card shadow-md rounded-2 p-3 " style="background-color: #f2f2f4;" >
                        <form [formGroup]="formComanda" >
                            <div class="row mb-3">
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12  ">
                                    <div class="contenido3">
                                        <label for="">Contacto</label>
                                        <input formControlName="contacto" placeholder="Ingrese contacto" type="text" class="form-control form-control-sm" >
                                    </div>
                                </div>
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12  ">
                                    <div class="contenido3">
                                        <label for="">Forma de Pago</label>
                                        <select  formControlName="pago"  class="custom-select custom-select-sm rounded-1 "  >
                                            <ng-container *ngFor="let tp of tiposPago" >
                                                <option [value]=tp.cod >{{tp.nombre}}</option>
                                            </ng-container>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  " style="display: block;" >
                                    <div class="contenido3">
                                        <label for="">Calle Principal</label>
                                        <input formControlName="principal" type="text" class="form-control form-control-sm" >
                                    </div>
                                </div>
                                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  " style="display: block;">
                                    <div class="contenido3">
                                        <label for="">Calle Secundaria</label>
                                        <input formControlName="secundaria" type="text" class="form-control form-control-sm" >
                                    </div>
                                </div>
                                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  " style="display: block;">
                                    <div class="contenido3">
                                        <label for="">Referencia </label>
                                        <input formControlName="referencia" type="text" class="form-control form-control-sm" >
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div class="container divSelect">
                            <div class="col-xxl-8 col-xl-8 col-lg-10 col-12  ">
                                <div class="row ">
                                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12  ">
                                        <!-- <button class="btn btn-sm rounded-1 btn-block btnPedido" [disabled]="formComanda.invalid" >
                                            Hacer Pedido
                                        </button> -->
                                        <button class="btn btn-sm rounded-1 btn-block btn-success"  (click)="comandaFinal(formComanda.value)"
                                        [disabled]="formComanda.invalid || banderaAC  " >
                                        <i [ngClass]="{'fa-save':!banderaAC, 'fa-rotate fa-spinner':banderaAC}" class="fa"> </i>
                                        <span *ngIf="!banderaAC"> Realizar Pedido</span>
                                        <span *ngIf="banderaAC"> Realizando Pedido....</span>
                                      </button>
                                    </div>
                                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 ">
                                        <button class="btn btn-sm rounded-1 btn-block btn-secondary" (click)="closeModal()" >
                                            Cancelar
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Modal Ticket Comanda -->
<ng-template #modalPrintComanda   class="modal fade" data-backdrop="static" data-keyboard="false">
    <div class="modal-header " >
    </div>
        <div class="" id="ticket">
            <h6 class="fw-bold" style=" font-size: 13px; text-align: center; " >
                 ORDEN PEDIDO #{{idComanda}}
            </h6>
            <div>
                <label for="" style="display: flex; justify-content: start; margin-left: 4px; " > <strong style="margin-right: 3px; ">Retira: </strong> {{ contactoFinal}} </label>
            </div>
            <div>
                <h6 class="fw-bold" style=" font-size: 13px; text-align: center; font-weight: 500; " >
                   DETALLE ORDEN
               </h6>
               <div class="table-responsive mt-3" style="font-family: 'Montserrat';font-size: 12px; ">
                <table class="table table-hover   ">
                    <thead class="thead">
                        <tr class="text-center ">
                            <th style="font-family: 'Montserrat';font-size: 12px; " >PRODUCTO</th>
                            <th style="font-family: 'Montserrat';font-size: 12px; " >CANT.</th>
                            <th style="font-family: 'Montserrat';font-size: 12px; ">PRECIO</th>
                        </tr>

                    </thead>
                    <tbody>
                        
                        <tr class="text-center" *ngFor="let prod of shoppingCart">
                            <td style="font-size: 13px;">
                                {{prod.nombre_producto}}
                            </td>
                            <td style="font-size: 13px;">

                                <span style="margin: 0px 5px;">
                                    {{prod.quantity}}
                                </span>

                            </td>
                            <th style="font-size: 13px;">
                                <span class=" columTable"> $ {{prod.precioReal * prod.quantity | number:'1.2-2' }}
                                </span>
                            </th>

                        </tr>
                        <tr style="background-color: #f2f2f4 !important;">
                            <th></th>
                            <th class="text-center " style="font-family: 'Montserrat';font-size: 12px; ">TOTAL </th>
                            <th class="text-center " style="font-family: 'Montserrat';font-size: 12px; ">$ {{totalComanda2(shoppingCart) | number:'1.2-2'}}</th>
                            <th></th>
                        </tr>

                    </tbody>
                </table>
                <div class="" style="text-align: center; margin-top: 5px;" >
                    <label for="">
                        Gracias por su compra!!
                    </label>
                </div>
            </div>
            </div>
        </div>
        <div class="divSelect mb-3"  style="display: flex ; justify-content: center; align-items: center;  margin-top: 5px;" >
            <button class="btn btn-sm btn-success rounded-1 shadow-md" printSectionId="ticket" ngxPrint (click)="closeFinal()" >
                Imprimir
            </button>
       
        </div>
   
</ng-template>
