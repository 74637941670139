import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/shared/services/services.service';

@Component({
  selector: 'app-whats-app',
  templateUrl: './whats-app.component.html',
  styleUrls: ['./whats-app.component.scss']
})
export class WhatsAppComponent implements OnInit {
  public textWhatsapp = '';
  public closeResult: string;
  constructor(
    private modalCtrl: NgbModal,
    private webService: ServicesService,
    private toaster: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  async contactWhatsapp(whatsappModal) {
    this.textWhatsapp = '';
    this.modalCtrl.open(whatsappModal, { ariaLabelledBy: 'modal-basic-title', centered: true, windowClass: 'modal-whatsapp' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      if (this.textWhatsapp) {
        this.webService.getInformacion().then((datainfo: any) => {
          window.open("https://wa.me/" + datainfo[0].whatsapp + "?text=" + this.textWhatsapp + ".", "_blank");
        });
      } else {
        this.toaster.warning('Ingrese el texto del mensaje', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else if (reason === ModalDismissReasons) {
      return `with: ${reason}`;
    }
  }

}
