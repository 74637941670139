import { Component, OnInit } from '@angular/core';

import { ServicesService } from '../../shared/services/services.service';


@Component({
  selector: 'app-politics-and-terms',
  templateUrl: './politics-and-terms.component.html',
  styleUrls: ['./politics-and-terms.component.scss']
})
export class PoliticsAndTermsComponent implements OnInit {

  public loadingAll = false;
  public viewInformation = {
    politicasPrivacidad: false,
    terminosCondiciones: false,
    politicasEnvioEntrega: false,
    preguntasPlacetopay: false,
    politicaAPP: false,

  }
  public configuracion: any;


  constructor(
    private webService: ServicesService,

  ) { }

  async ngOnInit() {
    await this.getConfiguration();
  }

  async getConfiguration() {
    await this.webService.getConfiguracion().then(async (resconf) => {
      this.configuracion = resconf[0];
      if (resconf[0].terminosCondiciones == 1) {
        await this.selectInformationView(5);
        // Boton Terminos
        let tc = document.getElementById('btn-terminos');
        tc.style.background = this.configuracion.colorPrincipal;
        tc.style.color = this.configuracion.colorLetra;
        // Boton Privacidad
        let pp = document.getElementById('btn-privacidad');
        pp.style.background = this.configuracion.colorPrincipal;
        pp.style.color = this.configuracion.colorLetra;
        // Boton Envio
        let ee = document.getElementById('btn-envio');
        ee.style.background = this.configuracion.colorPrincipal;
        ee.style.color = this.configuracion.colorLetra;
        let eapp = document.getElementById('btn-app');
        eapp.style.background = this.configuracion.colorPrincipal;
        eapp.style.color = this.configuracion.colorLetra;
      } else {
        this.webService.goHome()
      }
    });
  }

  async selectInformationView(identificador) {
    this.viewInformation = {
      politicasPrivacidad: false,
      terminosCondiciones: false,
      politicasEnvioEntrega: false,
      preguntasPlacetopay: false,
      politicaAPP: false,
    }
    switch (identificador) {
      case 1: {
        this.viewInformation.politicasPrivacidad = true;
        break;
      }
      case 2: {
        this.viewInformation.terminosCondiciones = true;
        break;
      }
      case 3: {
        this.viewInformation.politicasEnvioEntrega = true;
        break;
      }
      case 4: {
        this.viewInformation.preguntasPlacetopay = true;
        break;
      }
      case 5: {
        this.viewInformation.politicaAPP = true;
        break;
      }
    }
    // Ir hacia arriba
    await this.webService.goUpPage();
  }

}
