<br>
<body class="d-flex flex-column pt-5">

    <div class="container">
        <p class="suscribe">
            ¡Mantente informado! Suscríbete a Nuestro Boletín de Noticias en {{informacion.nombre | uppercase}}
        </p>
        <div class="pt-4  divSelect" >
            <div class="content  ">
                <form class="subscription ">
                  <input class="add-email" type="email" placeholder="Ingrese su correo electrónico">
                  <button class="submit-email" type="button">
                    <span class="before-submit"  >  Subscribirse</span>
                    <span class="after-submit">Thank you for subscribing!</span>
                   </button>
                </form>

              </div>

        </div>
        <div class="pb-5 mb-5 mt-4 divSelect ">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                    He leído y acepto la política de privacidad
                </label>
              </div>  
        </div>

    </div>

  
    <!-- Footer -->
    <footer class="mt-5" style="background-color:#EDEDED">
      <div class="container-fluid py-5">
        <div class="row py-4">
          <div class="col-lg-3 col-md-6 mb-4 mb-lg-0 " >   
            <div class="divSelect" >
                <img class="logo" [src]="urlBase+configuracion?.imgLogo+'?alt=media'" alt="" width="181px" height="80px">

            </div>
            <div class="divSelect" >
                <p class="nameEmpresa" >{{informacion.nombre | uppercase}}</p>

            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-6 mb-4 mb-lg-0">
            <h6 class=" font-weight-bold mb-4">Contáctanos</h6>
            <div>
                <h6 class="font-weight-bold" >Email:</h6>
                <p class="pContacto" >{{informacion?.email}}</p>
                <h6 class="font-weight-bold">Teléfono:</h6>
                <p  class="pContacto">{{informacion?.telefonos}}</p>
            </div>

          </div>
          <div class="col-lg-3 col-md-6 col-6 mb-4 mb-lg-0">
            <h6 class=" font-weight-bold mb-3">Síguenos en nuestras redes</h6>
            <ul class="list-unstyled mb-0">
              <li class="mb-3"><a type="button" (click)="goToSocialNetwork(informacion?.facebook)" target="_blank" title="facebook" class="text-dark " >           <i class="bi bi-facebook icoFooter"></i> Facebook </a></li>
              <li class="mb-3"><a type="button" (click)="goToSocialNetwork(informacion?.instagram)" target="_blank" title="Instagram" class="text-dark "><i class="fa fa-instagram icoFooter" i>  </i> Instagram</a></li>
              <li class="mb-3"><a type="button" (click)="goToSocialNetwork(informacion?.youtube)" target="_blank" title="Youtube" class="text-dark "><i class="fa fa-youtube icoFooter" >  </i> Youtube</a> </li>
              <li class="mb-3"><a type="button" (click)="goToSocialNetwork(informacion?.tiktok)" target="_blank" title="TikTok" class="text-dark "><i class="fa fa-tiktok icoFooter" > </i> TikTok</a></li>
              <li class="mb-3"><a type="button" (click)="goToSocialNetwork(informacion?.twitter)" target="_blank" title="twitter" class="text-dark "><i class="fa fa-twitter icoFooter" > </i> Twitter</a> </li>

            </ul>
          </div>
          <div class="col-lg-3 col-md-6 col-6 mb-4 mb-lg-0">
            <h6 class=" font-weight-bold mb-3">Encuentra nuestro local</h6>
            <div>
                {{informacion.direccion}}
            </div>
            <h6 class=" font-weight-bold mt-4 mb-4">Nuestros Términos</h6>
            <div>
                <!-- {{informacion.direccion}} -->
            </div>
            <!-- <div class="ml-2" >
                <div>
                    <h6 class="font-weight-bold" >Email:</h6>
                    <p class="pContacto" >{{informacion?.email}}</p>
                    <h6 class="font-weight-bold">Teléfono:</h6>
                    <p  class="pContacto">{{informacion?.telefonos}}</p>
                </div>
            </div> -->

          </div>

        </div>
      </div>
  
      <!-- Copyrights -->
      <div class="py-2" id="divFooter">
        <div class="container-fluid " >
            <div class="d-flex" style="justify-content: space-between;" >
                <p class=" mb-0 py-2 " style="font-size: 14px;" >{{footerText}}</p>
                <p type="button" class=" mb-0 py-2 " style="font-size: 14px;" (click)="goToSocialNetwork('https://www.sofpymes.com')"  >Powered by PuntoPymes</p>

            </div>
        </div>
      </div>
    </footer>
    <!-- End -->
  
  </body>
