
<div class="text-minuscula-texto d-grid gap-2 "  >

    <div class="divSelect "  >
        <div class="row w-75 ">

            <button class="btn btn-sm btn-block fw-normal text-light"  id="div-catalogo2" style="border-radius: 22px; pointer-events: none; ">
                TODOS LOS PRODUCTOS
            </button>
        </div>

    </div>

    <div class="border-bottom "  style="border: none !important;" >
        <div class="loading-catalogo pl-3 pr-3" *ngIf="loadingAll">
            <div class="progress">
                <div class="progress-bar progress-bar-striped progress-bar-animated bg-secondary" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
            </div>
            <p class="mt-3 text-size-12">Obteniendo catálogo, espere un momento ...</p>
        </div>

        <aside class="float-left w-100 bg-light pt-2   " >

            <ul class="cl-menu " style="border: none !important;"   *ngFor="let g of grupos"   >
                <li *ngIf="g.view_catalogo" class="tipo-cursor" style="border-radius: 8px !important ;">
                    <a class="border" style="border-radius: 8px !important  " *ngIf="!g?.subgrupos?.viewSubgrupo" (click)="getProductos2(g)">
                        <span> <i class="bi bi-dot" [ngStyle]="{'color': this.configuracion?.colorPrincipal}"></i> {{g.nombre}}</span>
                    </a>

                    <a class="border"  style="border-radius: 8px !important ; " *ngIf="g.subgrupos?.viewSubgrupo">
                        <span><i class="bi bi-dot" [ngStyle]="{'color': this.configuracion?.colorPrincipal}"></i>{{g.nombre}}</span>
                        <i class="fas fa-angle-right float-right text-size-18" [ngStyle]="{'color': this.configuracion?.colorPrincipal}"></i>
                        <!-- [ngStyle]="{'background-color': this.configuracion?.colorPrincipal}" -->
                        <span *ngIf="g.subgrupos?.viewSubgrupo" class="badge  rounded-5 float-right mr-2 badgeCant "  style="font-weight: 500 !important;  "  >{{g.subgrupos.length}}</span>
                    </a>

                    <ul class="animate__animated animate__fadeIn animate__faster" *ngIf="g.subgrupos?.viewSubgrupo">
                        <li *ngFor="let s of g.subgrupos">
                            <a (click)="getProductos(g, s)">{{s.nombre | titlecase }} </a>
                        </li>
                    </ul>
                </li>

            </ul>
            <ul>
                <ng-container  >
                    <li class="" style="position: relative;">
                        <div class=" text-size-18 w-100  pt-2 text-center" id="div-pagination" *ngIf="atributes_config.viewBtnPagnation">
                            <div class="row ">
                                <div class="col  " style="float: right;">
                                    <button class="btn btn-sm rounded-pill flagChange "   (click)="paginationCatalogue(grupos, 'previous')" style="height: 1.7em;"  >
                                        <!-- <i class="fas fa-arrow-alt-circle-left mb-3" [ngStyle]="{'color': this.configuracion?.colorPrincipal}" (click)="paginationCatalogue(grupos, 'previous')"></i> -->
                                        <i class="bi bi-caret-left-fill"></i>
                                    </button>
    
                                </div>
                                <div class="col" style="float: left;">
                                    <!-- <i class="fas fa-arrow-alt-circle-right ml-3"  [ngStyle]="{'color': this.configuracion?.colorPrincipal}" (click)="paginationCatalogue(grupos, 'next')"></i> -->
                                    <button class="btn btn-sm rounded-pill flagChange"  (click)="paginationCatalogue(grupos, 'next')" style="height: 1.7em;"  >
                                        <!-- <i class="fas fa-arrow-alt-circle-left mb-3" [ngStyle]="{'color': this.configuracion?.colorPrincipal}" (click)="paginationCatalogue(grupos, 'previous')"></i> -->
                                        <i class="bi bi-caret-right-fill"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ng-container>
            </ul>
        </aside>
    </div>

</div>

<!-- <div class="w-100 text-minuscula-texto" id="div-catalogo2">
    <button type="button" class="btn btn-sm btn-block text-justify mb-2" id="btn-categorias">
        <button class="icn-circle ml-3">
            <i class="fas fa-stream text-left"></i>
        </button>
        <span class="ml-4">Categoría Productos</span>
    </button>

    <div class="border-bottom">
        <div class="loading-catalogo pl-3 pr-3" *ngIf="loadingAll">
            <div class="progress">
                <div class="progress-bar progress-bar-striped progress-bar-animated bg-secondary" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
            </div>
            <p class="mt-3 text-size-12">Obteniendo catálogo, espere un momento ...</p>
        </div>

        <aside class="float-left w-100 bg-light">

            <ul class="cl-menu" *ngFor="let g of grupos">
                <li *ngIf="g.view_catalogo" class="tipo-cursor">
                    <a class="border" *ngIf="!g.subgrupos.viewSubgrupo" (click)="getProductos(g , g.subgrupos[0])">
                        <span>{{g.nombre}}</span>
                        <i class="fas fa-angle-right float-right text-size-18"></i>
                    </a>

                    <a class="border" *ngIf="g.subgrupos.viewSubgrupo">
                        <span>{{g.nombre}}</span>
                        <i class="fas fa-angle-right float-right text-size-18"></i>
                        <span *ngIf="g.subgrupos.viewSubgrupo" class="badge badge-secondary badge-pill float-right mr-2">{{g.subgrupos.length}}</span>
                    </a>

                    <ul class="animate__animated animate__fadeIn animate__faster" *ngIf="g.subgrupos.viewSubgrupo">
                        <li *ngFor="let s of g.subgrupos">
                            <a (click)="getProductos(g, s)">{{s.nombre}}</a>
                        </li>
                    </ul>
                </li>
            </ul>

        </aside>

        <div class="text-size-18 w-100 border pt-2" id="div-pagination" *ngIf="atributes_config.viewBtnPagnation">
            <i class="fas fa-arrow-alt-circle-left mb-3" (click)="paginationCatalogue(grupos, 'previous')"></i>
            <i class="fas fa-arrow-alt-circle-right ml-3" (click)="paginationCatalogue(grupos, 'next')"></i>
        </div>
    </div>
</div> -->




<!-- <div class="container-lg pt-2">
	<div class="row w-100" >
        <div class=" divSelect col-lg-12 col-md-8 pb-2 d-grid gap-2" >
            <button class="btn rounded-pill btn-sm fw-bold"  id="div-catalogo2">
                TODOS LOS PRODUCTOS
            </button>
        </div>
    <div class="loading-catalogo pl-3 pr-3" *ngIf="loadingAll">
        <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated bg-secondary" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
        </div>
        <p class="mt-3 text-size-12">Obteniendo catálogo, espere un momento ...</p>
    </div>
		<div class="col-lg-12 col-md-8  ">
			<div class="accordion pointer " id="accordionExample"  >
				<div class=""  *ngFor="let g of grupos; let i=index" style="text-align: left; background-color: #ffffff;border-radius: 5px;border-bottom: 1px solid white  !important; ">
					<div  *ngIf="g.view_catalogo"  class="card-header pointer" id="headingOne" >
						<h2 class="mb-0" style="font-size: 0.80rem;" type="button" >									
							<a  class=""   >
								<span (click)="getProductos(g , g.subgrupos[0])"> {{g.nombre}}</span>
								<i id="colorArrow{{i}}" style="float: right;" *ngIf="g.subgrupos.viewSubgrupo" type="button" data-toggle="collapse" [attr.data-target]="'#collapse'+i" aria-expanded="false"  class="fa fa-chevron-down toggle"></i>
							</a>
						</h2>
					</div>
					<div class="collapse " [attr.id]="'collapse'+i" *ngIf="g.subgrupos.viewSubgrupo">
						<div class="card-body" >
							<ul class="list-group " >
                                <ng-container *ngFor="let s of g.subgrupos">
                                    <li  type="button" style="border-radius: 8px;" (click)="getProductos(g, s)"  class="list-group-item mt-2"><a  style="font-size: 0.85rem;"> <i class="bi bi-dash"></i> {{s.nombre |titlecase}}</a></li>
                                </ng-container>							
							</ul>
						</div>
					</div>
				</div>
                <div class="text-size-18 w-100  pt-3" id="div-pagination" style="position: relative;"  *ngIf="atributes_config.viewBtnPagnation">
                    <i class="fas fa-arrow-alt-circle-left mb-3" (click)="paginationCatalogue(grupos, 'previous')"></i>
                    <i class="fas fa-arrow-alt-circle-right ml-3" (click)="paginationCatalogue(grupos, 'next')"></i>
                </div>		
			</div>
		</div>
	</div>
</div> -->