<!-- <nav class="navbar navbar-expand-lg navbar-light bg-light border rounded-top" id="menu-original">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fas fa-bars"></i>
  
    </button> -->
    <div class="row w-100 divSelect ">
        <div class="col-lg-12 col-md-12 col-sm-6 col-12 ">
            <select class="custom-select custom-select-sm  text-secondary rounded-pill shadow-sm " style="border: none; font-style: italic; "
            (change)="orderByFilter($event.target.value)">
            <option value="asc">
                ↓↑&emsp;Menor a Mayor
            </option>
            <option value="desc">
                ↑↓&emsp;Mayor a Menor
            </option>
        </select>
        </div>
    </div>

    <!-- <div class="collapse navbar-collapse text-mayuscula " id="navbarNav">
        <ul class="navbar-nav main-navbar-responsivo mr-auto text-size-14 w-25  text-center">
            

            <li class="nav-item li-menu tipo-cursor" *ngIf="viewFilter">
                <select class="form-control form-control-sm text-minuscula text-secondary rounded-3"
                    (change)="orderByFilter($event.target.value)">
                    <option value="asc">
                        ↓↑&emsp;Menor a Mayor
                    </option>
                    <option value="desc">
                        ↑↓&emsp;Mayor a Menor
                    </option>
                </select>
            </li>
        </ul>
    </div> -->

    <!-- <div class="collapse navbar-collapse text-mayuscula" id="navbarNav">
        <ul class="navbar-nav main-navbar-responsivo mr-auto text-size-14 w-100 text-center">
            <li class="nav-item li-menu tipo-cursor">
                <a class="nav-link" (click)="goHome()">
                    <i class="fas fa-home icon-menu"></i>
                    -
                    <span>{{menu.home}}</span>
                  
                </a>
            </li>

            <li class="nav-item li-menu tipo-cursor">
                <a class="nav-link" (click)="goProductsCatalogue()">
                    <i class="fas fa-gifts icon-menu"></i>
                    -
                    <span>{{menu.catalogo}}</span>
                </a>
            </li>

            <li class="nav-item li-menu tipo-cursor">
                <a class="nav-link" (click)="goAboutUs()">
                    <i class="fas fa-info icon-menu"></i>
                    -
                    <span>{{menu.sobre_nosotros}}</span>
                </a>
            </li>

            <li class="nav-item li-menu tipo-cursor" *ngIf="information.blog">
                <a class="nav-link" target="_blank" (click)="goBlog()">
                    <i class="fas fa-link icon-menu"></i>
                    -
                    <span>{{menu.blog}}</span>
                </a>
            </li>

            <li class="nav-item li-menu tipo-cursor" *ngIf="viewFilter">
                <select class="form-control form-control-sm text-minuscula text-secondary"
                    (change)="orderByFilter($event.target.value)">
                    <option value="asc">
                        ↓↑&emsp;Menor a Mayor
                    </option>
                    <option value="desc">
                        ↑↓&emsp;Mayor a Menor
                    </option>
                </select>
            </li>
        </ul>
    </div> -->
<!-- </nav> -->

<!-- <nav class="navbar navbar-expand-lg navbar-light bg-light" id="menu-catalogo">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fas fa-bars"></i>
    </button>

    <span class="float-right text-minuscula-texto">Catálogo</span>

    <div class="collapse navbar-collapse mt-3 text-mayuscula" id="navbarNav">
        <app-catalogo></app-catalogo>
    </div>
</nav> -->


  <nav class="navbar  navbar-light bg-light" id="menu-catalogo">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <span class="float-right text-minuscula-texto">Catálogo</span>

      <div class="collapse mt-4 divSelect " id="navbarToggleExternalContent">
        <app-catalogo></app-catalogo>

      </div>
    </div>
  </nav>

