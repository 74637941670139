import { Component, OnInit } from '@angular/core';
// import { WorkerService } from './shared/services/worker.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'portalHubX';

  constructor(){
    
  }
  ngOnInit(): void {
    
    // throw new Error('Method not implemented.');
    // this.serviceWorker.initUpdateChecker();
  }
  
}
