import { APP_INITIALIZER, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { from } from 'rxjs';

import { HomeComponent } from '../app/pages/home/home.component';
import { CarritoComponent } from '../app/pages/carrito/carrito.component';
import { LoginAdminComponent } from '../app/pages/login/login-admin/login-admin.component';
// import { LoginUserComponent } from '../app/pages/login/login-user/login-user.component';
import { ProductsComponent } from '../app/pages/products/products.component';
import { AdminComponent } from '../app/pages/profile/admin/admin.component';
import { UserComponent } from '../app/pages/profile/user/user.component';
import { AboutUsComponent } from '../app/pages/about-us/about-us.component';
import { PoliticsAndTermsComponent } from '../app/pages/politics-and-terms/politics-and-terms.component';
import { VaucherComponent } from '../app/pages/vaucher/vaucher.component';

import { GuardsGuard } from '../app/collaborator/guards/guards.guard';
import { ServicesService } from '../app/shared/services/services.service';
import { InicioComponent } from './pages/inicio/inicio.component';
import { RedirectGuard } from './collaborator/guards/redirect.guard';
// import { CatalogoComponent } from './shared/catalogo/catalogo.component';
// export function initializeApp(routeService: ServicesService): () => Promise<void> {
//   return (): Promise<void> => {
//     return routeService.rutaDefecto().toPromise().then(route => {
//       // console.log('route', route);
      
//       if (route) {
//         routes[0].redirectTo = route; // Actualiza la ruta por defecto
//         // console.log('routes[0].redirectTo',routes[0].redirectTo);
//         console.log('RUTAS', routes);
        
//       }
//     });
//   };
// }
const routes: Routes = [
  // {
  //   path: '',
  //   component: HomeComponent
  // },
  {
    path: '',
    canActivate: [RedirectGuard],
    // component: ProductsComponent

    // loadChildren: () => import('./path/to/your-module.module').then(m => m.YourModuleModule)
  },

  // { path: '', redirectTo: '',   pathMatch: 'full' }, // Aquí se actualizará la ruta por defecto

  // {
  //   path: '',
  //   component: InicioComponent
  // },
  {
    path: 'home/:value',
    component: HomeComponent
  },
  {
    path: 'carrito',
    component: CarritoComponent, canActivate: [GuardsGuard]
  },
  {
    path: 'admin',
    component: LoginAdminComponent, canActivate: [GuardsGuard]
  },
  {
    path: 'products',
    component: ProductsComponent
  },
  {
    path: 'product/:type/:value/:value2',
    component: ProductsComponent
  },
  {
    path: 'administrator',
    component: AdminComponent, canActivate: [GuardsGuard]
  },
  {
    path: 'user',
    component: UserComponent, canActivate: [GuardsGuard]
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
  },
  {
    path: 'politics',
    component: PoliticsAndTermsComponent,
  },
  {
    path: 'inicio',
    component: InicioComponent,
  },

  {
    path: 'extras',
      loadChildren:()=>import('./componentes/componentes.module').then(m=>m.ComponentesModule)
    // }
  },
  {
    path: 'administrador',
      loadChildren:()=>import('./administrador/administrador.module').then(m=>m.AdministradorModule), canActivate: [GuardsGuard]
    // }
  },
  {
    path: 'admin_usuario',
      loadChildren:()=>import('./usuario/usuario.module').then(m=>m.UsuarioModule)
    // }
  },
  {
    path: 'catalogo',
    // loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    component: ProductsComponent

  },
  {
  //   path: 'extras',
  //   loadChildren:()=>import('./componentes/componentes.module').then(m=>m.ComponentesModule)
  // }
    // path: 'vaucher/:id',
    path: 'vaucher',
    component: VaucherComponent,
  }

];

export function initializeApp(routeService: ServicesService) {
  return (): Promise<any> => {
    return routeService.rutaDefecto().toPromise().then(route => {
      routes[0].redirectTo = route;
      console.log('routes -======> ', routes[0]);
      
    });
  };
}

@NgModule({
  // imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  // exports: [RouterModule]
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    ServicesService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ServicesService],
      multi: true
    }
  ]
})

export class AppRoutingModule {


  constructor(
    // private webService: ServicesService,
  ) {
    // let config:any=[];
    // let ruta:any;
    // this.webService.rutaDefecto().subscribe((data:any)=>{
    //   config=data;
    //   console.log('config ==> ',config);
    // });
   
    // routes[0].component = config;
    // console.log('IMPRIME EN ROUTES',routes);
  }
}
