import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServicesService } from 'src/app/shared/services/services.service';
import { LoginAdminComponent } from '../login/login-admin/login-admin.component';
import { LoginUserComponent } from '../login/login-user/login-user.component';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {
  public videoYoutube;
  public configuracion: any;
  public videoY:any;
  public video2:any;
  banderaCardVideo = false;

  public cartProducts = {
    number: 0,
    total: 0.00
  }
  public closeResult: string;
  public loadingAll = false;
  public clientLogin = {
    name: '',
    imagen: '',
    login: false,
    rol: ''
  }
  public companyNane;
  public textoValores:any;
  public searchProd = '';
  public textWhatsapp = '';
  public imagenBanner = [];

  urlBase = environment.firebaseUrl;
  idShop = environment.idShop;
  urlPortada :any;
  arrayServices=[
    {id:1,'titulo':'Reparación Motor', 'desc':'Desarrollo completo, limpieza y rectificación del motor. Cambio de piezas y ensamblaje según especificaciones','ico':''  },
    {id:2,'titulo':'Revisión Técnica', 'desc':'Desarrollo completo, limpieza y rectificación del motor. Cambio de piezas y ensamblaje según especificaciones','ico':''  },
    {id:3,'titulo':'Mantenimiento Preventivo', 'desc':'Desarrollo completo, limpieza y rectificación del motor. Cambio de piezas y ensamblaje según especificaciones','ico':''  },
    {id:4,'titulo':'Diagnóstico Electrónico', 'desc':'Desarrollo completo, limpieza y rectificación del motor. Cambio de piezas y ensamblaje según especificaciones','ico':''  },
  ];
  information:any=[];
  @ViewChild('inicio') inicioDiv!: ElementRef;
  @ViewChild('nosotros') nosotrosDiv!: ElementRef;
  @ViewChild('contacto') contactoDiv!: ElementRef;
  botones:any=[];
  servicios:any=[];
  servicioDefault:any;
  promoDefault:any;
  constructor(
    private webService: ServicesService,
    private modalCtrl: NgbModal,
    private toaster: ToastrService,
    private router: Router,
    private domSanitizer: DomSanitizer
  ) { }

  async ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'smooth' }); 
    this.getBanner();
    this.getBotones();
    this.getServicios();

    this.loadingAll = true;
    await this.getConfiguracion();
    await this.getInformacion();
    await this.webService.observableLoginUser().subscribe((resLog: any) => {
      this.clientLogin = {
        name: resLog.name,
        imagen: resLog.imagen,
        login: resLog.login,
        rol: resLog.rol
      }
      // console.log("suscritoo", this.clientLogin);
    });
    // Suscribirse a cambios del carrito
    await this.webService.observableProductsCart().subscribe((rescart: any) => {
      // console.log("suscrito car", rescart);
      this.cartProducts = {
        number: rescart.number,
        total: rescart.total
      }
    });
    this.loadingAll = false;
  }

  async getBanner() {
    await this.webService.getImagesBanner().then((data: []) => {
      // console.log('banners',data);     
      this.imagenBanner = data;
    });
  }

  getBotones(){
    this.webService.getGeneral('botonesAcceso/').then((data:any)=>{
       if(data.rta){
        this.botones= data.data;
        console.log('data',this.botones);
        let color  = this.botones[0]?.colorBoton;
        document.documentElement.style.setProperty('--color-btn', color);

       }else{
        this.botones = [];
        let color  = this.configuracion?.colorPrincipal;
        document.documentElement.style.setProperty('--color-btn', color);
       }
    })
  }

  getServicios(){
    this.webService.getGeneral('productoservicios/').then((data:any)=>{
      console.log('servicios -=> ',data);
      if(data.rta){
        this.servicios= data.data;
       }else{
        this.servicios = [];
       }
    })
  }


  //   async getInformacion() {
  //   this.webService.getInformacion().then(async (dataInfo: any) => {
  //     this.informacion = dataInfo[0];
  //     if (dataInfo[0].video) {
  //       this.videoYoutube = this.webService.getIdVideoYoutube(dataInfo[0].video);
  //     } else {
  //       this.videoYoutube = '';
  //     }
  //   });
  // }
  isEmpty(obj: any): boolean {
    return Object.keys(obj).length === 0;
  }
  async getInformacion() {
    await this.webService.getInformacion().then((resinfo: any) => {
      // console.log(resinfo);
      this.information=resinfo[0];
      console.log('information.mision',this.information);
      
      this.companyNane = resinfo[0]?.nombre;
      // localStorage.setItem('empresa',JSON.stringify(resinfo[0].nombre) )
      this.textoValores = resinfo[0]?.valores;
      // console.log(this.textoValores);
      this.urlPortada = this.urlBase+this.configuracion?.imgFooter+'?alt=media'
      let color  = this.information.colorLetraSlogan;
      let colorLetra  = this.configuracion.colorLetra;
      let colorLetraSecundario  = this.configuracion.colorLetraSecundario;
      console.log('color', color); 
      document.documentElement.style.setProperty('--color-font-portada', color);
      document.documentElement.style.setProperty('--color-letter-primary', colorLetra);
      document.documentElement.style.setProperty('--color-letter-secondary', colorLetraSecundario);
      let urlV = this.webService.obtenerCodigoVideo( this.information.video)
      let urlV2 = this.webService.obtenerCodigoVideo( this.information.youtube)
      let url = 'https://www.youtube.com/embed/'+urlV;    
      let url2 = 'https://www.youtube.com/embed/'+urlV2;    
      this.videoY =  url ;
      this.video2 =  url2 ;

    });
  }

  async getConfiguracion() {
    await this.webService.getConfiguracion().then(async (data: any) => {
      // console.log('data', data);
      
      this.configuracion = data[0];
      this.urlPortada = this.urlBase+this.configuracion?.imgFooter+'?alt=media'
      let color  = this.configuracion.colorPrincipal;
      document.documentElement.style.setProperty('--dynamic-color', color);
     

      await this.isAutenticatedClient(this.configuracion).then(async (resauth: any) => {
        // Obtener productos del carrito y su total
        if (resauth.rta == true) {
          await this.webService.getproductsCart({ id_cliente: resauth.data.PersonaComercio_cedulaRuc }).then(async (resprod: any) => {
            this.cartProducts = {
              number: resprod.data.length,
              total: await this.webService.calculateTotalCartProducts(resprod.data)
            }
          });
        }
      });
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else if (reason === ModalDismissReasons) {
      return `with: ${reason}`;
    }
  }

  async loginUser() {
    this.modalCtrl.open(LoginUserComponent, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      console.log(reason);
    });
  }

  async loginAdmin() {
    this.modalCtrl.open(LoginAdminComponent, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      console.log(reason);
    });
  }
  async signOff() {
    await this.webService.signOuth(this.configuracion.loginStorage).then((resClose: any) => { });
    this.cartProducts = {
      number: 0,
      total: 0.00
    }
    await this.webService.goHome();
    await this.webService.refreshPage(this.configuracion);
  }

  async goClientProfile() {
    await this.webService.isAuthenticatedClient(this.configuracion.loginStorage).then(async (reslogin: any) => {
      if (reslogin.rta == true) {
        if (reslogin.data.rol == 'Client') {
          this.webService.goUserProfile();
          // console.log("ar al cliente");
        }
        if (reslogin.data.rol == 'Administrator') {
          this.webService.goAdminProfile();
          // console.log("ar al administrador");
        }
      }
    });
  }

  async goHome() {
    // this.webService.goHomeClean();
    this.webService.goHome();
    
  }
  async goHome2() {
    // this.webService.goHomeClean();
    // this.webService.goHome();
    // window.open('https://www.ruedasygarruchas.com/', "_self");    
    // window.open('http://publigorras.com.ec', "_self");
  }
  async goProductsCatalogue() {
    this.webService.goProductsCatalogue();
  }

  async goProductsCatalogue2() {
    // console.log("AQUI desde inicio");
    this.webService.goProducts1();
  }
  async goShoppingCartUser() {
    await this.isAutenticatedClient(this.configuracion).then(async (resauth: any) => {
      if (resauth.rta == true) {
        if (this.cartProducts.number > 0) {
          await this.webService.goShoppingCart();
        } else {
          this.toaster.warning('Su carrito de compras esta vacio', '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      } else {
        await this.loginUser();
      }
    });
  }

  async isAutenticatedClient(configuracion) {
    let auth;
    await this.webService.isAuthenticatedClient(configuracion?.loginStorage).then((login: any) => {
      auth = login;
      if (login.rta == true) {
        this.clientLogin = {
          name: login.data.nameUser,
          imagen: login.data.imagen,
          login: true,
          rol: login.data.rol
        }
      } else {
        this.clientLogin = {
          name: '',
          imagen: '',
          login: false,
          rol: ''
        }
      }
    });
    return auth;
  }

  scrollToElement($element:any): void {
    setTimeout(() => {
      $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
     }, 400);
  }

  goSection(event:any){
    console.log('event',event);
    if(event.siteO=='inicio'){ 
      this.scrollToElement(this.inicioDiv.nativeElement)
    }else if(event.siteO=='nosotros'){
      this.scrollToElement(this.nosotrosDiv.nativeElement)
    }else if(event.siteO=='commerce'){

    }else{
      this.scrollToElement(this.contactoDiv.nativeElement)   
    } 
  }

  redirecTO(url){
    window.open(url, '_blank')
  }

  transformText(texto:string){
    return this.webService.cadenaByPuntos(texto);
   }

   verDetalleServicio(servicio, modal){
    this.servicioDefault = servicio;
    this.modalCtrl.open(modal,{size:'lg'})
   }
   verDetallePromo(promo, modal){
    this.promoDefault = promo;
    this.modalCtrl.open(modal,{size:'lg'})
   }

   closeModal(){
    this.modalCtrl.dismissAll();
   }

}
