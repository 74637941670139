import { AfterContentInit, Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { ServicesService } from '../../shared/services/services.service';
import { group } from 'console';
declare function paginadorCatalogo():any;
@Component({
  selector: 'app-catalogo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.scss']
})
export class CatalogoComponent implements OnInit , AfterContentInit, OnChanges {

  public empresa: any;

  public loadingAll = false;
  public loadingAllCategory = false;

  public informacion: any;

  public configuracion: any;

  public closeResult: string;

  public grupos: [];
  public subgrupos: [];

  public atributes_config = {
    viewBtnPagnation: false
  }
  // emitGrupoSubGrupo:any=[];
  @Output() emitGrupoSubGrupo = new EventEmitter<any>();


  constructor(
    private toaster: ToastrService,
    private webService: ServicesService,
    public router: Router,
    private modalCtrl: NgbModal,
    private activateRoute: ActivatedRoute,

  ) {
 
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes', changes);
    
  }

  async ngOnInit() {
    this.getSecction();
    await this.getConfiguracion();
    await this.getDataEmpresa();
    await this.getGrupos(this.empresa, 1)

  }

  ngAfterContentInit(): void {
      // menuCategorias()
      this.getConfiguracion();
      paginadorCatalogo();
  }



  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else if (reason === ModalDismissReasons) {
      return `with: ${reason}`;
    }
  }

  public async getDataEmpresa() {
    await this.webService.getEmpresaService().then(async (resEmpr: any) => {
      this.empresa = resEmpr.data[0];
      // console.log("Empres", this.empresa);
    });
  }

  async getConfiguracion() {
    await this.webService.getConfiguracion().then(async (data: any) => {
      this.configuracion = data[0];
      // Boton Categorias
      // let bcat = document.getElementById('btn-categorias');
      // bcat.style.background = this.configuracion.colorPrincipal;
      // bcat.style.color = this.configuracion.colorLetra;

      //span MENU CATEGORIAS
      // let menuCat = document.getElementById('div-catalogo2');
      // menuCat.style.background = this.configuracion.colorPrincipal;
      // menuCat.style.color = this.configuracion.colorLetra;

      let color = this.configuracion.colorPrincipal;
      document.documentElement.style.setProperty('--dynamic-color', color);
      const rgbaColor = this.webService.hexToRgba(color, 0.1);
      document.documentElement.style.setProperty('--lighter-tone', rgbaColor);
      //span MENU CATEGORIAS


      // Obtener las categorias
      // await this.webService.getTemporaryCatalogue().then(async (rescatalogue: any) => {
      //   if (rescatalogue) {
      //     this.grupos =  await rescatalogue;
      //     this.viewButtonPagination2(this.grupos);
      //     this.ordenarPorNumOrden(this.grupos,'orden')  
      //     // this.viewButtonPagination();
      //   } else {
      //   }
      // });
    });
  }

 async getSecction(){
    await this.webService.getTemporaryCatalogue().then(async (rescatalogue: any) => {
      if (rescatalogue) {
        this.grupos =  await rescatalogue;
        this.viewButtonPagination2(this.grupos);
        this.ordenarPorNumOrden(this.grupos,'orden')  
        // this.viewButtonPagination();
      } else {
      }
    });
  }

  // tipoWeb = 1 => Tienda normal
  // tipoWeb = 2 => Tienda por tallas
  // tipoWeb = 3 => Tienda con 2 BD
  // tipoWeb = 4 => Tienda Internet
  async getGrupos(empresa, tipo_web) {
    if (tipo_web == 1 || tipo_web == 2 || tipo_web == 4) {
      this.loadingAll = true;
      await this.webService.getGruposService(empresa.url_billing, 'filter').then(async (resCategorias: any) => {
        console.log('print',resCategorias);
        this.grupos = await resCategorias.data
        if (!resCategorias.error) {
          if (resCategorias.rta == true) {
            await this.getSubgrupos(resCategorias.data, empresa.url_billing).then(async (resSubgrup: any) => {
              console.log(' =========== > ', resSubgrup);
              this.emitGrupoSubGrupo.emit(resSubgrup);
              await this.webService.saveTemporaryCatalogue(resSubgrup);
              this.grupos = resSubgrup;
              this.ordenarPorNumOrden(this.grupos,'orden')  
              // console.log('grupos', this.grupos);
              
              await this.viewButtonPagination2(this.grupos);
            });
          } else {
            this.toaster.warning('Catálogo de la tienda vacio', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          }
        } else {
          this.toaster.error('Error al obtener las categorias', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
      this.loadingAll = false;

    }

    if (tipo_web == 3) {
      console.log("Tienda con 2 BD, codigo se encuentra en proceso");
    }

  }

  async getSubgrupos(grupos, url_billing) {
    this.subgrupos = [];
    this.loadingAll = true;

      const gr = grupos.map( async(group_) =>{
      group_.nombre =  this.webService.convertStringTypeSentence(group_.nombre);
      // console.log('nombre del grupo', group_.nombre);

      await this.webService.getSubgruposService(url_billing, group_.idgrupo).then(async (resSubgrup: any) => {
          //  console.log('resSubgrup',resSubgrup);
          //  console.log('resSubgrupDAA',resSubgrup.data);
           group_.subgrupos = resSubgrup.data;
           return resSubgrup.data
          });
      
      // group_.subgrupos = await this.webService.getSubgruposService(url_billing, group_.idgrupo)
      // .then((resSubgrup: any) => { 
      //   console.log('los subgrupos',resSubgrup);
        
      //   return resSubgrup.data } );
      
    });
    

    this.loadingAll = false;
    return grupos;
  }

  async getProductos(grupo, subGrupo) {
    let type = 'selectCatalogue';
    this.router.navigateByUrl('product/' + type + '/' + grupo.idgrupo + '/' + subGrupo.id_sub);
    
  }
  async getProductos2(grupo) {
    console.log('grupo 2', grupo);
    
    let type = 'selectCatalogue';

    await this.webService.getSubgruposService(this.empresa.url_billing, grupo.idgrupo)
    .then((resSubgrup: any) => { 
      console.log('los subgrupos',resSubgrup);
      //  grupo.data
       console.log('los grupo.data',grupo.data);

       this.router.navigateByUrl('product/' + type + '/' + grupo.idgrupo + '/' + resSubgrup.data[0].id_sub);

       } );

    
  }

  async paginationCatalogue(grupos, type) {
    await this.webService.paginationCatalogueGroup(grupos, type).then((respagi) => {
      grupos = respagi;
    });
  }

  async viewButtonPagination() {
    if (this.grupos.length > this.webService.size) {
      this.atributes_config.viewBtnPagnation = true;
      // let ul = document.getElementById('div-pagination');
      // ul.style.color = this.configuracion.colorPrincipal;
    }
  }
  async viewButtonPagination2(grupos) {
    console.log('grupos.length, ', grupos.length);
    console.log('this.webService.size, ', this.webService.size);
    
    if (grupos.length > this.webService.size) {
      this.atributes_config.viewBtnPagnation = true;
      // let ul = document.getElementById('div-pagination');
      // ul.style.color = this.configuracion.colorPrincipal;
    }
  }

  // ============================== NUEVO CODIGO ====================================

  ordenarPorNumOrden(arr: any[], criterio: string) {
    const datosOrdenados = arr.sort((a, b) => {
      const ordenA = Number(a.orden);
      const ordenB = Number(b.orden);
    
      if (ordenA < ordenB) {
        return -1;
      }
      if (ordenA > ordenB) {
        return 1;
      }
      return 0;
    });
}

}
