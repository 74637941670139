<!-- <app-submenu></app-submenu> -->
<app-header *ngIf="!isEmpty(information) && !isEmpty(configuracion) "   [information]="information" [configuracion]="configuracion" > </app-header>

<ngx-loading [show]="loadingAll" [config]="{ backdropBorderRadius: '3px',  fullScreenBackdrop: true}" [template]="">
</ngx-loading>

<!-- Categoria, Banner, Sugerencias -->
<section class="container-fluid mt-5 pt-5   animate__animated animate__fadeIn">
    <div class="row pt-2 pb-2 pl-3 pr-3 ">
        <!-- Catalogo -->
        <div class="col-lg-3 col-md-6  order-lg-1 order-2 text-center mt-3" id="catalogo-home">
            <app-catalogo></app-catalogo>
             <!-- <app-side-bar></app-side-bar> -->
        </div>

        <!-- Menu y Banner -->
        <div class="col-lg-6 order-lg-2 order-1 pt-2">
            <!-- <app-menu></app-menu> -->
            <div class="text-center w-100">
                <ngb-carousel id="ngbDiapositiva" style="   box-shadow: 1px 3px 4px 2px rgba(0,0,0,0.3); border-radius: 12px; ;
                ">
                    <ng-template ngbSlide *ngFor="let i of imagenBanner">
                        <img class="auto  animate__animated animate__fadeIn" style="   box-shadow: 1px 3px 4px 2px rgba(0,0,0,0.3); border-radius: 12px;"
                            [src]="urlBase+i.url+'?alt=media'" id="imgBanner" alt="Random first slide">
                        <div class="carousel-caption"></div>
                    </ng-template>
                </ngb-carousel>
            </div>
        </div>

        <!-- Sugerencias y promociones -->
        <div class="col-lg-3 col-md-6 order-lg-3 order-md-6  order-4 text-center mt-3">
            <div class="">
                <!-- <button class="btn btn-sm btn-block " id="btnCardComprarMP"  style="border-radius: 22px;">
                    MÁS POPULARES
                </button> -->

                <div class="divSelect ">
                    <div class="row w-75 ">

                        <button class="btn btn-sm btn-block fw-normal text-light btnColorConfig" id="btnCardComprarMP"
                            style="border-radius: 22px;">
                            MÁS POPULARES
                        </button>
                    </div>

                </div>


                <div class="col-lg-12 divSelect" style="margin: 0; padding: 0;">
                    <main role="main">
                        <div class="product" style="border-radius: 12px;">
                            <div *ngIf="productsSold.length > 0">
                                <ngb-carousel [interval]="10000">
                                    <ng-template ngbSlide *ngFor="let p of productsSold; let i = index">
                                        <figure style="border-radius: 12px; padding: 5px;" id="prodMV">

                                            <img class=" "
                                                *ngIf="p.imagenPrincipal == '1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2'"
                                                src="../../../assets/img/imgDefault/sinProd.png" alt=""
                                                style="width: 100%; height: auto; object-fit: scale-down;  border-radius: 7px; ">
                                            <img class=" "
                                                *ngIf="p.imagenPrincipal != '1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2'"
                                                [src]="rutaUrl+p.imagenPrincipal+'?alt=media'" alt=""
                                                style="width: 100%; height: auto; object-fit:cover  ;  border-radius: 7px; ">


                                        </figure>

                                        <div class="product-description" style="border-radius: 12px;">

                                            <div class="info">
                                                {{p.nombre_producto}}

                                            </div>

                                            <div class="price">
                                                <!-- <p class=""> -->
                                                <!-- <span  *ngIf="p.precioOferta > 0"  >
                                    {{p.precioOferta | number: '1.2'}}</span> -->
                                                {{p.precioReal}}$
                                                <!-- <span>  {{p.precioReal}}</span> -->
                                                <!-- </p> -->
                                            </div>
                                            <div class="pb-2">
                                                <!-- id="btnCardCarrito{{i}}" -->
                                                <button class="btn rounded-pill  btnColorConfig"
                                                    (click)="setMethodAddCart(p)">
                                                    <!-- <i class="bi bi-cart4"></i> -->
                                                    <i class="fas fa-cart-plus "></i>
                                                </button>
                                            </div>
                                        </div>
                                        <!-- <div class="carousel-caption"></div> -->
                                    </ng-template>
                                </ngb-carousel>

                            </div>
                            <div class="text-center" *ngIf="productsSold.length == 0">

                                <img class="w-100" src="../../../assets/img/imgDefault/sinProd.png" alt="">
                                <!-- <img class="w-100"
                    src="https://drive.google.com/uc?export=view&id=1_bZD-9CEmvOAdxd08WjfuKWIuhnIxc-2"
                    alt=""> -->
                            </div>
                        </div>
                    </main>
                </div>


            </div>

        </div>

    </div>

    <hr class="mt-5">

</section>

<!-- Productos de Promocion, Nuevos, Grupos del billing -->
<section class="container-fluid "  id="menuSectionLG">
    <h1 style="padding-top: 2em ;" class="titleColorConfig" style="font-size: 2rem;">NUESTROS PRODUCTOS </h1>
    <nav class="navbar navbar-expand-lg pb-3    " style="background-color: transparent;">
        <div class="container-fluid">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <i class="fas fa-bars"></i>
            </button>
            <div class="collapse navbar-collapse" style="z-index: 0 !important;"  id="navbarNav">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0 m-auto">
                    <li class="nav-item " style="padding: 0.5em ;">
                        <a class="nav-link btn-sm rounded-pill " style="padding-left: 1em ; padding-right: 1em;z-index: 0 !important; "
                            aria-current="page" type="button" id="promociones"
                            (click)="tendenciasSelect('promociones')">Promociones</a>
                    </li>
                    <li class="nav-item" style="padding: 0.5em 3em;">
                        <a class="nav-link  btn-sm rounded-pill " style="padding-left: 2.5em ; padding-right: 2.5em;"
                            routerLinkActive="active" id="nuevos" type="button"
                            (click)="tendenciasSelect('nuevos')">Nuevos</a>
                    </li>
                    <li class="nav-item" style="padding: 0.5em ;">
                        <a class="nav-link  btn-sm rounded-pill" type="button" id="grupos"
                            style="padding-left: 1.5em ; padding-right: 1.5em;"
                            (click)="tendenciasSelect('grupos')">Categorías</a>
                    </li>

                </ul>
            </div>
        </div>
    </nav>

</section>

<section id="menuSectionSM " style="display: none;">
    <h1 class="titleColorConfig" style="padding-top: 2em;" id="" style="font-size: 1.5rem;">NUESTROS PRODUCTOS </h1>
    <div class="row divSelect py-5">
        <div class="col-md-3 col-5 p-1">
            <a class="nav-link btn-sm rounded-pill text-center text-dark" aria-current="page" type="button"
                id="promociones1" (click)="tendenciasSelect('promociones')">Promociones</a>
        </div>
        <div class="col-md-3 col-5 p-1">
            <a class="nav-link  btn-sm rounded-pill text-center text-dark " routerLinkActive="active" type="button"
                (click)="tendenciasSelect('nuevos')">Nuevos</a>
        </div>
        <div class="col-md-3 col-5 p-1">
            <a class="nav-link  btn-sm rounded-pill text-center text-dark" type="button"
                (click)="tendenciasSelect('grupos')">Categorías</a>
        </div>
    </div>

    <!-- <ul class="navbar-nav me-auto mb-2 mb-lg-0 m-auto">
        <li class="nav-item " style="padding: 0.5em ;"  >
          <a class="nav-link btn-sm rounded-pill " style="padding-left: 1em ; padding-right: 1em;" aria-current="page" type="button"  id="promociones" (click)="tendenciasSelect('promociones')">Promociones</a>
        </li>
        <li class="nav-item" style="padding: 0.5em 3em;">
          <a class="nav-link  btn-sm rounded-pill "  style="padding-left: 2.5em ; padding-right: 2.5em;" routerLinkActive="active" id="nuevos" type="button"  (click)="tendenciasSelect('nuevos')">Nuevos</a>
        </li>
        <li class="nav-item" style="padding: 0.5em ;">
          <a class="nav-link  btn-sm rounded-pill" type="button" id="grupos"   style="padding-left: 1.5em ; padding-right: 1.5em;" (click)="tendenciasSelect('grupos')">Categorías</a>
        </li>

      </ul> -->

</section>


<section class="container-fluid">

    <!-- <app-card-component [productos]="productos" [configurationVariables]="configurationVariables" [configuracion]="configuracion" ></app-card-component> -->


    <owl-carousel-o [options]="productosPromoNuevos2">
        <ng-template carouselSlide *ngFor="let p of productos; let i=index">
            <div class=" col " style="margin-bottom: 2em;">
                <div class="card " style="background-color: #f7f7f7;position: relative; ">
                    <span *ngIf="p.precioOferta > 0"
                        class="position-absolute top-0 end-0 translate-middle badge  badgeStar rounded-pill"
                        style="margin-top: 2em; margin-left: 1em;  ">
                        5 <i class="bi bi-star-fill text-warning "></i>
                    </span>
                    <div class=" classContImg divSelect " type="button" (click)="modalViewDetailProduct( p)">
                        <span *ngIf="p.precioOferta > 0"
                            class="position-absolute top-0 start-0 translate-middle badge  badgeDesc "
                            style="margin: 2.35em; border-top-right-radius: 6px; border-bottom-right-radius: 6px; border-bottom-left-radius: 0px;border-top-left-radius: 0px;">
                            {{calculateDescount(p.precioOferta, p.precioReal )}} %
                        </span>
                        <img class="card-img-top " *ngIf="p.imagenPrincipal == '1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2'"
                            src="{{rutaUrl}}{{configuracion.imgLogo}}?alt=media" alt=""
                            style="width: 100%; height: auto; object-fit: scale-down;  border-radius: 7px; opacity: 0.4;  ">
                        <img class="card-img-top " *ngIf="p.imagenPrincipal != '1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2'"
                            src="{{rutaUrl}}{{p.imagenPrincipal}}?alt=media" alt="">
                    </div>

                    <div class="card-body"
                        style="background-color: white;border-bottom-left-radius: 8px; border-bottom-right-radius: 8px; ">
                        <h5 class="card-title">{{p.pro_nom |uppercase}}</h5>
                        <div class="d-flex justify-content-start ">
                            <label class="labelStock"><strong>Disponible: </strong> {{p.stockactual | number:
                                '1.0' }} </label>
                        </div>
                        <div class="d-flex justify-content-between ">
                            <p class="text-size-14 text-center sin-espacios" (click)="modalViewDetailProduct(p)"
                                *ngIf="configurationVariables.mostrar_precio">
                                <span style="font-family:'Helvetica' ; font-weight: bold; " class="priceSpan"> $
                                    {{p.precioReal | number: '1.2'}} </span>
                                <span class="text-secondary mr-2 text-subrayado-gray"
                                    *ngIf="p.precioOferta > 0">${{p.precioOferta | number: '1.2'}} </span>
                            </p>
                            <button class="btn btn-sm rounded-2" (click)="setMethodAddCart(p)"
                                style="background-color: rgb(250, 239, 218); height: 3.5em; width: 3.5em; ">
                                <i class="bi bi-cart-plus   fw-bold icoCar " style="font-size: 1.2em;"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="col m-4" >
            <div class="card  box p-2" >
                <div class="card-body "   >
                    <div class="row">
                        <div class="col-lg-5 col-md-5 col-6  divSelect" style="padding: 0; margin: 0;" id="colImgProd" type="button" (click)="modalViewDetailProduct(p)">
                                <img class=" "  *ngIf="p.imagenPrincipal == '1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2'" src="../../../assets/img/imgDefault/sinProd.png" alt="" style="width: 100%; height: auto; object-fit: scale-down;  border-radius: 7px; ">
                                <img class=" "  id="imgProdCard" *ngIf="p.imagenPrincipal != '1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2'"  [src]="rutaUrl+p.imagenPrincipal+'?alt=media'" alt="" >
                        </div>
                        <div class="col-lg-7 col-md-7 col-6 " >
                            <div >
                            <div class="product-text text-center">
                              <p>{{p.pro_nom | uppercase}} </p>
                            </div>
                            <p class="text-size-11  mb-1 text-dorado divSelect">
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                          </p>
                          <div class="card-body text-minuscula pt-1 "  (click)="modalViewDetailProduct(p)">
                            <p class="text-size-14 text-center sin-espacios"
                                *ngIf="configurationVariables.mostrar_precio">
                                <span class="text-secondary mr-2 text-subrayado-gray"
                                    *ngIf="p.precioOferta > 0">${{p.precioOferta | number: '1.2'}} </span>
                                    <br>
                                <span style="font-family:'Helvetica' ; font-weight: bold;font-size: 1.3em; "> $ {{p.precioReal | number: '1.2'}} </span>
                            </p>
                            <p class="fw-bold  font-family='Helvetica'" style="font-size: 12px; color:#2478c1; text-align: center;">
                              Disponible en stock {{p.stockactual}}                             </p>
                            
                            <p class="text-size-11 sin-espacios mt-1 pt-1 pb-1 bg-light rounded border border-warning text-center"
                                *ngIf="p.combo_venta_volumen">
                                <span *ngFor="let pric of p.precios">
                                    <strong *ngIf="pric.combo">
                                        {{pric.combo}}
                                        <br>
                                    </strong>
                                </span>
                            </p>
                        </div>
                            <div class=" button-group btn-group-sm divSelect ">
                                <button  style="padding: 0.35em 1em;" class="btn btn-warning rounded-pill m-1 fw-bold" id="btnAddCar{{i}}" title="Agregar a carrito 1" (click)="setMethodAddCart(p)">
                                  Agregar a carrito 
                                </button>                  
                              </div>
                        </div>       
                        </div>
                    </div>    
                </div>
              </div>
           </div> -->


        </ng-template>

    </owl-carousel-o>

    <!-- <hr class="linea-bottom-ploma"> -->
    <!-- <div *ngIf="productos.length > 0">
        <owl-carousel-o [options]="productosPromoNuevos">
            <ng-template carouselSlide *ngFor="let p of productos">

                <div class="mr-3">
                    <div class="card shadow p-3 mb-3 bg-white rounded div-product animate__animated animate__zoomIn">
                        <div class="w-100 text-center" (click)="modalViewDetailProduct(p)">
                            <img class="img-productos"
                                src="https://drive.google.com/uc?export=view&id={{p.imagenPrincipal}}" alt="">
                            <p class="text-size-11 mt-2 mb-1 text-dorado">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                            </p>
                        </div>

                        <div class="card-body text-minuscula pt-1 height-125 p-0">
                            <p class="text-size-14 text-center sin-espacios"
                                *ngIf="configurationVariables.mostrar_precio">
                                <span class="text-secondary mr-2 text-subrayado-gray"
                                    *ngIf="p.precioOferta > 0">${{p.precioOferta | number: '1.2'}} </span>
                                <span> $ {{p.precioReal | number: '1.2'}} </span>
                            </p>
                            <hr>
                            <p class="text-size-12 text-center sin-espacios">
                                {{p.pro_nom}}
                            </p>
                            <p class="text-size-11 sin-espacios mt-1 pt-1 pb-1 bg-light rounded border border-warning text-center"
                                *ngIf="p.combo_venta_volumen">
                                <span *ngFor="let pric of p.precios">
                                    <strong *ngIf="pric.combo">
                                        {{pric.combo}}
                                        <br>
                                    </strong>
                                </span>
                            </p>
                        </div>

                        <br>
                        <div class="row text-center border-top p-1" >
                            <div class="col-4 text-right div-icn-prod" (click)="setMethodAddCart(p)">
                                <i class="fas fa-cart-plus"></i>
                            </div>
                            <div class="col-4 text-center div-icn-prod" (click)="modalViewDetailProduct(p)">
                                <i class="fas fa-eye"></i>
                            </div>
                            <div class="col-4 text-left div-icn-prod" (click)="sharedFacebook(p)">
                                <i class="fas fa-share-alt"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div> -->
    <div *ngIf="groups.length > 0">
        <owl-carousel-o [options]="productosPromoNuevos">
            <ng-template carouselSlide *ngFor="let g of groups;let j=index">


                <div class="row ">
                    <!-- <h1>Categorias</h1> -->
                    <div class="col  m-4">
                        <div class="card1  box p-2">
                            <div class="card-body ">
                                <div class="row">
                                    <!-- <div class="w-100 text-center" (click)="modalShowSubgrupos(showSubgrupos, g)" > -->
                                    <!-- <div class="w-100 text-center" (click)="redirectoCatalogue(g)"> -->
                                    <div class="w-100 text-center" (click)="modalShowSubgrupos(showSubgrupos, g)" >
                                        <img class="card-img-top" [src]="urlBase+g.img+'?alt=media'" alt=""
                                             *ngIf="g.img">
                                        <img class="card-img-top"  [src]="urlBase+configuracion?.imgLogo+'?alt=media'"
                                            alt=""  *ngIf="!g.img">
                                        <div class="col-12  pt-3">
                                            <div class="" style="font-family: 'Montserrat';" >
                                                <h6>{{g.nombre | uppercase}}</h6>
                                                <p style="font-size: 13px;" >{{g.descripcion}} </p>
                                            </div>
                                            <div class=" button-group btn-group-sm divSelect">

                                                <button style="opacity: .50; height: 2.25em !important; "
                                                    class="btn  bg-dark text-white rounded-circle"
                                                    title="Ver detalle producto">
                                                    <i (click)="redirectoCatalogue(g)" class="fa fa-eye"></i>
                                                </button>
                                                <!-- <button   style="opacity: .50;"  class="btn  bg-dark text-white rounded-circle m-1"  title="Ver detalle producto">
                                          <i (click)="modalShowSubgrupos(showSubgrupos, g)" class="fa fa-eye"></i>
                                        </button> -->
                                                <!-- <button style="opacity: .50;" (click)="sharedFacebookGroup(g)"
                                                    class="btn btn-warning rounded-circle m-1"
                                                    title="Compartir producto">
                                                    <i class="fa fa-share-alt"></i>
                                                </button> -->

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
    <div class="text-center" *ngIf="productos.length == 0 && groups.length == 0">
        <img class="mt-2 w-50" src="../../../assets/img/imgDefault/sinContenido.png">
    </div>
</section>

<!-- Mostrar promociones creadas en la pagina web -->
<section class="container-fluid mb-5" *ngIf="promocionesWeb.promociones.length > 0">

    <div class="row mb-4 mt-4 pl-4 pr-4 text-center">
        <div class="col-lg-12 bg-light pt-3 pb-3" id="div-promo"  >
            <span class="text-mayuscula-titulos text-size-17"> {{promocionesWeb.titulo}}</span>
        </div>
    </div>

    <owl-carousel-o [options]="promocionWeb">
        <ng-template carouselSlide *ngFor="let p of promocionesWeb.promociones">
            <div class="row ">
                <div class=" col " style="margin-bottom: 2em;">
                    <div class="card m-1 cardShadow " style="background-color: #f7f7f7;position: relative; ">

                        <div class=" classContImg divSelect " type="button"  (click)="modalViewDetailPromotion(viewDetailPromotionModal, p)">
                            <img class="card-img-top p-2" 
                                 [src]="rutaUrl+p.imgPromocion+'?alt=media'"alt=""
                                style=" ">
       
                        </div>
    
                        <div class="card-body"
                            style="background-color: white;border-bottom-left-radius: 8px; border-bottom-right-radius: 8px; font-family: 'Montserrat'; ">
                            <h5 class="card-title">{{p.pro_nom |uppercase}}</h5>
                            <div class="divSelect  ">
                                <p class="labelStock"><strong> {{p.nombrePromocion}}</strong> </p>
                            </div>
                            <div>
                                <label class="labelStock text-center"> {{p.descripcion}}</label>
                            </div>
        
                        </div>
                    </div>
                </div>
               
            </div>
        </ng-template>
    </owl-carousel-o>

</section>

<section class="container-fluid">
    <!-- <app-menu-responsivo></app-menu-responsivo> -->
</section>

<!-- Modal enviar sugerencias -->
<ng-template #enviarSugerenciaModal let-modal>

    <form class="header-search-form text-minuscula">

        <div class="col-lg-12 text-center mt-3">

            <div class="row">
                <div class="col-10 text-size-14 text-size-16 text-secondary">
                    Envíe su sugerencia
                </div>
                <div class="col-2">
                    <i class="far fa-times-circle" (click)="modal.dismiss('Cross click')"></i>
                </div>
            </div>

        </div>

        <hr>

        <div class="modal-body">

            <div class="form-group">
                <p>
                    <i class="fas fa-id-card mr-2 text-secondary"></i>
                    <label class="text-size-14 mb-3">Nombres Completos *</label>
                    <input type="text" class="form-control form-control-sm" name="nombres"
                        [(ngModel)]="datosSugerencia.nombres">
                </p>
            </div>

            <div class="form-group">
                <p>
                    <i class="fas fa-envelope mr-2 text-secondary"></i>
                    <label class="text-size-14 mb-3">Correo Electrónico *</label>
                    <input type="text" class="form-control form-control-sm" name="correo"
                        [(ngModel)]="datosSugerencia.correo">
                </p>
            </div>

            <div class="form-group">
                <p>
                    <i class="fas fa-phone-square-alt mr-2 text-secondary"></i>
                    <label class="text-size-14 mb-3">Número de teléfono *</label>
                    <input type="text" class="form-control form-control-sm" name="telefono"
                        [(ngModel)]="datosSugerencia.telefono">
                </p>
            </div>

            <div class="form-group">
                <p>
                    <i class="fas fa-envelope mr-2 text-secondary"></i>
                    <label class="text-size-14 mb-3">Mensaje *</label>
                    <textarea class="form-control" rows="5" name="mensaje"
                        [(ngModel)]="datosSugerencia.mensaje"></textarea>
                </p>
            </div>

        </div>

        <div class="text-center container-fluid">
            <button type="button" class="btn btn-outline-dark text-size-14 btn-sm btn-block"
                (click)="modal.close(datosSugerencia)" id="btn-enviar-sugerencia">
                Enviar
            </button>
        </div>

        <br>
    </form>

</ng-template>

<!-- Modal mostrar descripcion de la promocion -->
<ng-template #viewDetailPromotionModal let-modal>
    <div class="row bg-light rounded">
        <!-- <h1>MODAL DETALLE</h1> -->
        <div class="col-lg-6 text-center w-100 p-0">

            <img width="100%" height="350px" style="object-fit: cover;"
                [src]="rutaUrl+ promotionSelected.imgPromocion+'?alt=media'" alt="" class="p-2">
            <!-- <img width="100%" height="100%"
                src="https://drive.google.com/uc?export=view&id={{promotionSelected.imgPromocion}}" alt=""> -->
        </div>

        <div class="col-lg-6 text-center w-100 p-0">
            <div class="text-right p-3">
                <i class="far fa-times-circle text-danger" (click)="modal.dismiss('Cross click')"></i>
            </div>
            <div class="text-center p-0">
                <div>
                    <span class="text-size-20 text-mayuscula-titulos" *ngIf="promotionSelected.titulo">
                        {{promotionSelected.titulo}}</span>
                    <span class="text-size-20 text-mayuscula-titulos" *ngIf="!promotionSelected.titulo"> Titulo de la
                        promoción </span>
                </div>

                <hr>
                <div class="text-justify pl-3 pr-3">
                    <span class="text-minuscula-texto text-size-14">
                        {{promotionSelected.descripcion}}
                    </span>
                </div>

                <hr>
                <div class="mt-5 mb-5 pl-3 pr-3">
                    <button type="button" class="btn btn-circle animate__animated animate__jackInTheBox"
                        id="btn-go-promotion" (click)="modal.close(promotionSelected)"><i
                            class="fa fa-arrow-circle-right"></i></button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Alerta para visualizar los subgrupos -->
<ng-template #showSubgrupos let-modal>
    <div class="row text-minuscula pl-2 pr-2 pb-4 bg-light text-size-16">
        <div class="col-lg-12 mt-3">
            <div class="row">
                <div class="col-12 pt-2 pb-2 rounded border alert alert-dark">
                    <span class="float-left text-size-20"><i class="fa fa-th-large text-light mr-2"
                            aria-hidden="true"></i> {{groupSelected.nombre | uppercase}}</span>
                    <span class="float-right"><i class="far fa-times-circle text-danger"
                            (click)="modal.dismiss('Cross click')"></i></span>
                </div>
            </div>
        </div>

        <div class="col-lg-12">
            <div class="row pl-3 pr-3">
                <div class="col-lg-4 mt-3" *ngFor="let s of groupSelected.subgrupos">
                    <div class="card shadow p-3 mb-3 bg-white rounded div-product animate__animated animate__zoomIn">
                        <div class="w-100 text-center" (click)="modal.close(s)">

                            <img class="img-productos" [src]="rutaUrl+s.img+'?alt=media'" alt="" *ngIf="s.img">
                            <!-- <img class="img-productos" src="https://drive.google.com/uc?export=view&id={{s.img}}" alt=""
                                *ngIf="s.img"> -->
                            <img class="img-productos" src="../../../assets/img/imgDefault/sinProd.png" alt=""
                                *ngIf="!s.img">
                            <!-- <img class="img-productos"
                                src="https://drive.google.com/uc?export=view&id=1bW4FHKxVF0tHzYbiYTu1iEh4BaSYbRm2" alt=""
                                *ngIf="!s.img"> -->
                            <p class="text-size-11 mt-2 mb-1 text-dorado">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                            </p>
                        </div>

                        <div class="card-body text-minuscula pt-1 height-75">
                            <hr>
                            <p class="text-size-12 text-center pl-0 pr-0">
                                {{s.nombre | uppercase}}
                            </p>
                        </div>

                        <hr>
                        <div class="row text-center ">
                            <div class="col-12 text-center div-icn-prod" (click)="modal.close(s)">
                                <i class="fas fa-eye"></i>
                            </div>
                        </div>
                        <br>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<app-scroll-to-top></app-scroll-to-top>

<!-- <app-footer></app-footer> -->