import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';


import { ServicesService } from '../../shared/services/services.service';
import { LoginUserComponent } from '../../pages/login/login-user/login-user.component';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-detail-product',
  templateUrl: './detail-product.component.html',
  styleUrls: ['./detail-product.component.scss']
})
export class DetailProductComponent implements OnInit {

  @ViewChild("showVideoModal", {}) showVideoModal: TemplateRef<any>;
  client:any=[];
  //urlRutaImagenFirebase
  rutaUrl=environment.firebaseUrl;
  
  public loadingAll = false;
  public loadingAux = false;
  public productSelected: any = {
    tallas: [],
    colores: []
  };
  public configuracion: any = {};
  public login: any = {};
  public sizeGuide: any = [];
  public configurationVariables = {
    show_price: 1,
    show_attributes_product: false,
    guarnition: [],
    exists_referidos: false,
    referidos: [],
    spinner: false,
    productsSold: [],
  }
  public closeResult: string;
  urlVideo:any;
  information:any=[];
  productDefault2:any;
  constructor(
    private webService: ServicesService,
    private toaster: ToastrService,
    private modalCtrl: NgbModal,
    public router: Router,
    private elementRef: ElementRef
  ) { }

  async ngOnInit() {
    this.getInformation();
    let productDetail = this.webService.getProductSelectedDetail();
    await this.stablishDescription(productDetail.product).then((resdet: any) => {
      productDetail.product.desc_product_detail = resdet.desc_product_detail;
      productDetail.product.desc_product_detail_aux = resdet.desc_product_detail_aux;
      productDetail.product.show_more_detail = resdet.show_more;
    });
    let product = productDetail.product;
   
    // console.log("product", product);
    

    let checkReferidos = true;
    this.configuracion = productDetail.configuracion;
    this.login = productDetail.login;
    this.configurationVariables = {
      show_price: productDetail.show_price,
      show_attributes_product: productDetail.show_attributes_product,
      guarnition: [],
      exists_referidos: false,
      referidos: [],
      spinner: false,
      productsSold: []
    }
    let url;
    await this.webService.getUrlEmpresa().then(async (url_empresa) => {
      url = url_empresa;
    });
    // Agreagar solo 2 productos mas vendidos para mostrar si no hay referidos
    if (!product.referidos) {
      if (productDetail.productsSold.length > 0) {
        let aleatorio = [];
        for (let i = 0; i < 2; i++) {
          // Generar valor alearotio para no repetir
          let a = Math.floor(Math.random() * productDetail.productsSold.length);
          while (aleatorio.indexOf(a) == -1) {
            // Agregar posicion aleaoria
            this.configurationVariables.productsSold.push(productDetail.productsSold[a]);
            aleatorio.push(a);
          }
        }
      } else {
        this.configurationVariables.spinner = true;
        // await this.webService.getUrlEmpresa().then(async (url) => {
        await this.webService.getProductosNewService(url, this.configuracion, 2).then(async (resnew: any) => {
          if (resnew.rta == true) {
            await this.webService.obtainAndCalculatePriceProduct(resnew.data, this.configuracion, this.login).then(async (resprice) => {
              this.configurationVariables.productsSold = resprice;
            });
          }
        });
        // });
        this.configurationVariables.spinner = false;
      }
    }
    // Establecer talla y colores por defecto
    if (this.configuracion.tipo_web == 2) {
      if (product.tallas.length > 0) {
        await this.getSizeProduct(product.tallas[0].id_producto, product).then(async (reSize) => { });
        checkReferidos = false;
      } else {
        this.productSelected = product;
      }
      await this.webService.getGuiaTallasGroupId(url, product.id_grupo).then(async (resguia: any) => {
        this.sizeGuide = resguia;
      });
    } else {
      this.productSelected = product;
    }
    // Establecer guarniciones
    if (this.productSelected.guarnicion == true) {
      this.loadingAll = true;
      // await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.getGuarnitionsProduct(url, this.productSelected.id_producto).then((resguarn: any) => {
        if (resguarn.rta == true) {
          this.configurationVariables.guarnition = resguarn.data;
        } else {
          this.configurationVariables.guarnition = [];
        }
      });
      // });
      this.loadingAll = false;
    } else {
      this.configurationVariables.guarnition = [];
    }
    // Validar si se consulta los referidos
    if (checkReferidos == true) {
      await this.getProductReferidos(this.productSelected).then((resref) => {
        this.configurationVariables.referidos = resref;
      });
    }
    // Establecer colores del tema al boton
    if (document.getElementById('btn-add-cart')) {
      let bac = document.getElementById('btn-add-cart');
      bac.style.backgroundColor = this.configuracion.colorPrincipal;
      bac.style.color = this.configuracion.colorLetra;
      document.getElementById('btn-add-cart').onmouseover = function () {
        bac.style.opacity = '0.6';
      }
      document.getElementById('btn-add-cart').onmouseout = function () {
        bac.style.opacity = '1';
      }
    }
    // console.log("Configuracion", this.configuracion);
    // console.log("Var Configuracion", this.configurationVariables);
    // console.log("Producto seleccionado", this.productSelected);
    // this.urlVideo = this.productSelected.fotourl+'embed';
    // console.log('url IG', this.urlVideo);
    
  }

  async getInformation() {
    await this.webService.getInformacion().then(async (data: any) => {
      if (!data.error) {
        if (data[0]) {
          this.information = data[0];
          // console.log("Informacion", this.information);
          // this.formLogoDos.controls['url'].setValue(this.information.redirect_logo_dos)
        } else {
          console.log("No se ha encontrado information");
        }
      } else {
        this.toaster.error('No se ha podido acceder al servicio, comuniquese con su administrador', '', { timeOut: 4000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
      }
    });
  }

  async stablishDescription(p) {
    let num =  255;
    let resp = {
      desc_product_detail: '',
      desc_product_detail_aux: '',
      show_more: false
    }
    if (p.descripcion) {
      if (p.descripcion.length > num) {
        resp = {
          desc_product_detail: p.descripcion.slice(0, num) + ' ...',
          desc_product_detail_aux: p.descripcion.slice(0, num) + ' ...',
          show_more: true
        }
      }
    }
    return resp;
  }

  viewMoreDetail(p) {
    if(p.show_more_detail == true){
      this.productSelected.show_more_detail = false;
      this.productSelected.desc_product_detail = p.descripcion
    }else{
      this.productSelected.show_more_detail = true;
      this.productSelected.desc_product_detail = p.desc_product_detail_aux
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else if (reason === ModalDismissReasons) {
      return `with: ${reason}`;
    }
  }

  async dismissModal(type, data) {
    if (type == 'close') {
      this.modalCtrl.dismissAll({ type: type, data: data });
    } else {
      this.loadingAll = true;
      if(this.information.esPuntoVenta==1){
        this.modalCtrl.dismissAll({ type: type, data: data });

      }else{
      await this.verifyLoginClient(data, 'normal');

      }

      this.loadingAll = false;
    }
  }

  async imageSelectedView(imagen, product) {
    product.imagenPrincipal = imagen;
    this.productSelected = product;
  }

  async getSizeProduct(id, product) {
    let id_producto = id;
    this.loadingAll = true;
    await this.webService.selectSizeProduct(id_producto, product).then(async (reSize: any) => {
      this.productSelected = reSize;
      await this.getProductReferidos(this.productSelected).then((resref) => {
        this.configurationVariables.referidos = resref;
      });
    });
    this.loadingAll = false;
  }

  async selectedColorProduct(color, product) {
    this.loadingAll = true;
    await this.webService.selectColorProduct(color, product).then(async (rescolor) => {
      this.productSelected = rescolor;
      await this.getProductReferidos(this.productSelected).then((resref) => {
        this.configurationVariables.referidos = resref;
      });
    });
    this.loadingAll = false;
  }

  async quantityProduct(type, product) {
    this.loadingAll = true;
    await this.webService.settingQuantityProduct(type, product).then((resquant) => {
      if (resquant.rta == true) {
        this.productSelected = resquant.data;
      } else {
        this.toaster.warning(resquant.message, '', { timeOut: 3000, positionClass: 'toast-bottom-center', closeButton: true, progressBar: true });
      }
    });
    this.loadingAll = false;
  }

  async addGuarnition(e, guarnition, product) {
    await this.webService.addGuarnitionProduct(e.target.checked, guarnition, product).then((resproduct) => {
      this.productSelected = resproduct;
    });
  }

  // De aqui siempre se va agregar el producto al carrito
  async verifyLoginClient(product, type) {
    await this.webService.isAuthenticatedClient(this.configuracion.loginStorage).then(async (login: any) => {
      if (login.rta == true) {
        this.client = login.data;
        if (type == 'direct') {
          product.quantity = 1;
        }
        await this.addProductShoppingCart(product, login.data);
      } else {
        await this.loginClient();
      }
    });
  }
  // async verifyLoginClient(product, type) {
  //   await this.webService.isAuthenticatedClient(this.configuracion.loginStorage).then(async (login: any) => {
  //     if (login.rta == true) {
  //       this.loadingAll = true;
  //       this.loadingAux = true;
  //       await this.addProductShoppingCart(product, login.data, type);
  //       this.loadingAll = false;
  //       this.loadingAux = false;
  //     } else {
  //       await this.dismissModal('close', this.productSelected);
  //       await this.loginClient();
  //     }
  //   });
  // }
  // async verifyLoginClientFinal(product, type) {
  //   await this.webService.isAuthenticatedClient(this.configuracion.loginStorage).then(async (login: any) => {
  //     if (login.rta == true) {
  //       this.client = login.data;
  //       if (type == 'direct') {
  //         product.quantity = 1;
  //       }
  //       await this.addProductShoppingCart(product, login.data);
  //     } else {

  //       // this.setMethodAddCart(product,1)
  //               this.modalCtrl.open(this.modalFinalCustomer, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' })

  //       // let login={
  //       //   usuario:'9999999999',
  //       //   clave:'9999999999'
  //       // }
  //       // await this.loginClient2(`https://sofpymes.com/${environment.empresa}/common/movil/`, this.configuracion, login, 'login');
  //     }
  //   });
  // }

  // async addProductShoppingCart(product, client, type) {
  //   if (product.precioReal > 0) {
  //     await this.webService.addProductCart(product, client).then(async (res: any) => {
  //       if (res.rta == true) {
  //         this.toaster.success(res.mensaje, '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
  //       } else {
  //         this.toaster.warning(res.mensaje, '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
  //       }
  //     });
  //   } else {
  //     this.toaster.warning('No se puede agregar el producto, precio no válido', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
  //   }
  //   if (type == 'normal') {
  //     this.modalCtrl.dismissAll({ type: 'acept', data: product });
  //   }
  // }

  async addProductShoppingCart(product, client) {
    console.log('ENTRA PARA AGREGAR');
    let counter = 0; 
    let urlBilling = `https://sofpymes.com/${environment.empresa}/common/movil/`;
    this.loadingAll=true;
    if (product.precioReal > 0) {
      this.loadingAll = true;
      await this.webService.addProductCartSecond(product, client, this.configuracion, urlBilling ).then(async (res: any) => {
        if (res.rta == true) {
          this.toaster.success(res.mensaje, '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
          if(localStorage.getItem('carCount')){
            counter = parseFloat(localStorage.getItem('carCount')) ;
          }
          counter += 1;
          // localStorage.setItem('carCount', JSON.stringify(counter));
          this.webService.saveToLocalStorage('carCount', counter);
        } else {
          this.toaster.warning(res.mensaje, '', { timeOut: 2000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
        }
      });
      this.loadingAll = false;
      

    } else {
      this.toaster.warning('No se puede agregar el producto, precio no válido', '', { timeOut: 3000, positionClass: 'toast-bottom-full-width', closeButton: true, progressBar: true });
    }

  }

  async loginClient() {
    this.modalCtrl.open(LoginUserComponent, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      console.log(reason);
    });
  }

  async viewVideoProduct(showVideoModal, product) {
    this.modalCtrl.open(showVideoModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    if (document.getElementById('div-video')) {
      let dv = document.getElementById('div-video');
      dv.style.backgroundColor = this.configuracion.colorPrincipal;
      dv.style.color = this.configuracion.colorLetra;
    }
  }

  async viewReferidosProduct(showReferidosModal, product) {
    // console.log("Referidos", product);
    this.configurationVariables.referidos = [];
    this.loadingAll = true;
    let arr_prod = [];
    await this.webService.getUrlEmpresa().then(async (url) => {
      await this.webService.getReferidosProduct(url, product.id_producto).then(async (resref: any) => {
        if (resref.rta == true) {
          for (let p of resref.data) {
            await this.webService.getProductosCodigoService(url, p.codigo, this.configuracion).then(async (resproduct: any) => {
              if (resproduct.rta == true) {
                await this.webService.obtainAndCalculatePriceProduct(resproduct.data, this.configuracion, this.login).then((resprice: any) => {
                  // this.configurationVariables.referidos.push(resprice[0]);
                  arr_prod.push(resprice[0]);
                });
              }
            });
          }
          // Agregar tallas si la web es de ese tipo
          if (this.configuracion.tipo_web == 2) {
            await this.webService.createTallasProduct(arr_prod).then(async (resTalla) => {
              this.configurationVariables.referidos = resTalla;
            });
          } else {
            this.configurationVariables.referidos = arr_prod;
          }
          // console.log("estittos", this.configurationVariables.referidos);
        }
      });
    });
    this.loadingAll = false;
    this.modalCtrl.open(showReferidosModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      if (reason && reason != 1) {
        this.productSelected = reason;
      }
    });
  }

  async getProductReferidos(product) {
    this.configurationVariables.referidos = [];
    this.configurationVariables.spinner = true;
    this.configurationVariables.exists_referidos = false;
    let arr_prod = [];
    let productReferidos = [];
    if (product.referidos) {
      await this.webService.getUrlEmpresa().then(async (url) => {
        await this.webService.getReferidosProduct(url, product.id_producto).then(async (resref: any) => {
          if (resref.rta == true) {
            this.configurationVariables.exists_referidos = true;
            for (let p of resref.data) {
              await this.webService.getProductosCodigoService(url, p.codigo, this.configuracion).then(async (resproduct: any) => {
                if (resproduct.rta == true) {
                  await this.webService.obtainAndCalculatePriceProduct(resproduct.data, this.configuracion, this.login).then((resprice: any) => {
                    arr_prod.push(resprice[0]);
                  });
                }
              });
            }
            productReferidos = arr_prod;
            // Agregar tallas si la web es de ese tipo
            // if (this.configuracion.tipo_web == 2) {
            //   await this.webService.createTallasProduct(arr_prod).then(async (resTalla) => {
            //     productReferidos = resTalla;
            //   });
            // } else {
            //   productReferidos = arr_prod;
            // }
          }
        });
      });
    } else {
      productReferidos = this.configurationVariables.productsSold;
      this.configurationVariables.exists_referidos = false;
    }
    this.configurationVariables.spinner = false;
    return productReferidos;
  }

  async sharedFacebook(product) {
    let url = this.configuracion.dominioPagina + '/product/selectProduct/' + product.id_producto + '/' + 0;
    // console.log(url);
    let shared = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url);
    window.open(shared, 'sharer', '_blank');
  }

  async selectProductReferido(product) {
    this.loadingAll = true;
    let prod_aux: any;
    // Agregar tallas y colore si la web es de ese tipo
    if (this.configuracion.tipo_web == 2) {
      await this.webService.getUrlEmpresa().then(async (url) => {
        await this.webService.searchProductCodeName(url, product.tagDeGrupo + ' TALLA', this.configuracion).then(async (ressearch: any) => {
          if (ressearch.rta == true) {
            await this.webService.obtainAndCalculatePriceProduct(ressearch.data, this.configuracion, this.login).then(async (resPrice) => {
              await this.webService.createTallasProduct(resPrice).then(async (resTalla) => {
                for (let tc of resTalla[0].tallas) {
                  if (tc.talla == product.talla) {
                    product.colores = tc.colores;
                    product.tallas = resTalla[0].tallas;
                    break;
                  }
                }
                prod_aux = product;
              });
            });
          } else {
            prod_aux = product;
          }
        });
      });
    } else {
      prod_aux = product;
    }
    // Seleccionar color espeifico del producto
    for (let pa of prod_aux.colores) {
      if (pa.color == prod_aux.color) {
        pa.talla_color_selected = true;
      } else {
        pa.talla_color_selected = false;
      }
    }
    // Obtener lod referidos del producto
    await this.getProductReferidos(prod_aux).then((resref) => {
      this.productSelected = prod_aux;
      this.configurationVariables.referidos = resref;
    });
    // console.log("producto", this.productSelected);
    // console.log("referidos", this.configurationVariables.referidos);
    this.loadingAll = false;
  }

  async ViewSizeGuide(showSizeGuideModal) {
    // console.log(this.sizeGuide);
    this.modalCtrl.open(showSizeGuideModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg' }).result.then(async (result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

    if (document.getElementById('head-talla')) {
      let ht = document.getElementById('head-talla');
      ht.style.backgroundColor = this.configuracion.colorPrincipal;
      ht.style.color = this.configuracion.colorLetra;
    }
  }

  redirectoVideo(url:any){
    window.open(url, '_blank');
  }

  public setMethodAddCart(product, esPunto) {
    this.productDefault2=product;
    if( esPunto==1){
      // if (product.talla || product.guarnicion == true) {
      //   this.modalCtrl.open(this.modalFinalCustomer, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' })
      // } else {
      //   this.verifyLoginClientFinal(product, 'direct');
      // }
      // this.verifyLoginClientFinal(product, 'direct');

      // this.verifyLoginClient2(product, 'direct');


    }else{
      if (product.talla || product.guarnicion == true) {
        // this.modalViewDetailProduct(product);
      } else {
        this.verifyLoginClient(product, 'direct');
      }
    }


  }

  closeModal(){
    this.modalCtrl.dismissAll();
  }

}
