<div *ngIf="!isEmpty(information) && !isEmpty(configuracion) ">
    <app-header (redirectSection)="goSection($event)" [information]="information" [configuracion]="configuracion" ></app-header>
</div>
<div class="mainScreen animate__animated animate__fadeIn" [ngStyle]="{ 'background-image': 'url('+urlPortada+ ')' }"
    style="background-size: cover; background-position: center; width: 100%; z-index: 9999 !important ;  ">


    <div class="container-fluid" #inicio>
        <div class="row divSelect">
            <div class="col-xxl-10 col-xl-10  col-lg-10 col-12 ">
                <div class="container-fluid ">
                    <div class="row   " style="padding-bottom: 7em;">
                        <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-8 col-12  ">
                            <div class="centered-div">
                                <h3 class="pb-5 h3Portada colorLandScape ">Bienvenido a nuestra web</h3>
                                <div class="d-flex  animate__animated animate__backInLeft" style="">
                                    <h1 class="h1Portada colorLandScape">
                                        {{information.slogan}} |  <span  class="nameEmpresa">{{companyNane | uppercase }}</span> 
                                    </h1>
                                </div>
                                <div class="col-11  animate__animated animate__backInLeft">
                                    <div class="pt-5 pb-2 ">
                                        <p class="text-left colorLandScape" style="line-height: 1.35em; ">
                                            {{information.detalleSlogan}}
                                        </p>
                                    </div>
                                </div>

                                <div class="col-lg-5 pt-2">
                                    <div class="d-flex" style="justify-content: space-between;">
                                        <button class="btn btnIcoSM " (click)="redirecTO(information.facebook )" >
                                            <i class="bi bi-facebook icoSM"></i>
                                        </button>
                                        <button class="btn btnIcoSM" (click)="redirecTO(information.instagram)">
                                            <i class="bi bi-instagram icoSM"></i>
                                        </button>
                                        <button class="btn btnIcoSM " (click)="redirecTO(information.tiktok)">
                                            <i class="bi bi-tiktok icoSM"></i>
                                        </button>
                                        <button class="btn btnIcoSM " (click)="redirecTO( information.youtube )">
                                            <i class="bi bi-youtube icoSM"></i>
                                        </button>
                                        <button class="btn btnIcoSM " (click)="redirecTO(information.twitter)">
                                            <i class="bi bi-twitter icoSM"></i>
                                        </button>
                                    </div>

                                </div>
                                <div class="col-xxl-7 col-xl-10 col-lg-11 col-md-12 col-12 pt-3">
                                    <div class="row divSelect">
                                        <div class="col-6  mb-2" *ngFor="let btn of botones" >
                                            <button  (click)="redirecTO(btn.url)" class="btn bnt-sm rounded-pill btn-block py-1 btnExplora " [ngStyle]="{'background-color':btn.colorBoton, 'color':btn.colorLetra}" >
                                                {{btn.titulo}}
                                            </button>
                                        </div>
                                        <!-- <div class="col">
                                            <button class="btn bnt-sm rounded-pill  btnVerifica  btn-block py-1">
                                                Verifica el estado de tu vehículo
                                            </button>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class=" col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12 ">
                            <div class="centered-div promos "  style=" position: relative;"  *ngIf="idShop==70" >
                            
                                <!-- <div class="section full-height over-hide px-4 px-sm-0">
                                    <div class="container">
                                        <div class="row ">
                                            <div class="col-xxl-9 col-xl-12 col-lg-8  align-self-center">
                                                <div class="section mx-auto text-center slider-height-padding">
                                                    <ng-container  *ngFor="let i of imagenBanner; let index = index" >
                                                      <input class="checkbox index{{index+1}}" type="radio" id="slide-{{index+1}}" name="slider" checked/>
                                                      <label [ngStyle]="{'background-image': 'url(' + urlBase + i.url + '?alt=media)'}" for="slide-{{index+1}}"></label>
                                                    </ng-container>
                                                    <ul>
                                                        <ng-container  *ngFor="let i of imagenBanner; let index = index" >
                                                            <li [ngStyle]="{'background-image': 'url(' + urlBase + i.url + '?alt=media)'}"      (click)="verDetallePromo(i,modalPromo)">
                                                                <span class="" style="color: white; background-color: red;   padding-left: 5px; padding-right: 5px;"  >Promoción {{index+1}} </span>
                                                            </li>
                                                        </ng-container>    
                                                    </ul>
                                                </div>
                                            </div>
                                          </div>
                                    </div>
                                </div> -->
                               
                                <div class="text-center w-100" >
                      
                                    <ngb-carousel id="ngbDiapositiva" style="   box-shadow: 1px 3px 4px 2px rgba(0,0,0,0.3); border-radius: 12px; ;
                                    ">
                                        <ng-template ngbSlide *ngFor="let i of imagenBanner">
                                            <h3 class=" divSelect   titlePromo "  >Promociones</h3>
                                            <img class="auto  animate__animated animate__fadeIn" (click)="verDetallePromo(i,modalPromo)" style="   box-shadow: 1px 3px 4px 2px rgba(0,0,0,0.3); border-radius: 12px;"
                                                [src]="urlBase+i.url+'?alt=media'" id="imgBanner" alt="Random first slide">
                                            <div class="carousel-caption"></div>
                                        </ng-template>
                                    </ngb-carousel>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 pt-5" id="nosotros2" #nosotros  >
    <div class="row divSelect">
        <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-11 col-12  ">
            <div class="container-fluid ">
                <div class="row">
                    <!-- <div class="col-1"></div> -->
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12  ">
                        <div class=" d-flex " style="justify-content: end;">
                            <span class="rounded-pill px-2"
                                style="background-color: #ebebeb; color: gray;font-weight: 400; font-size: 14px; ">
                                {{information.fraseClave}}
                            </span>
                        </div>
                        <div>
                            <h1 class="nameEmpresa textSecondary" style="text-align: right;">{{companyNane | uppercase}}</h1>
                            <hr>
                        </div>
                        <p class="" style="text-align: right;">
                            {{information.fraseEmpresa}}
                        </p>
                    </div>
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 ">
                        <div class="">
                            <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/jxKyI3mhjbI " title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> -->
                            <iframe width="560" height="315" [src]="videoY | safe" title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div class="col-xxl-10 col-xl-10 col-lg-10 col-md-11 col-12  mt-5" *ngIf="idShop==70">
            <div class="container-fluid ">
                <div class="row divSelect ">

                    <!-- <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 ">
                        <div class="">
                            <iframe width="560" height="315" [src]="videoY | safe" title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </div> -->
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12  ">
                        <div class=" d-flex " style="justify-content: end;">
                            <span class="rounded-pill px-2"
                                style="background-color: #ebebeb; color: gray;font-weight: 400; font-size: 14px; ">
                                Video 
                            </span>
                        </div>
                        <div>
                            <h1 class="nameEmpresa textSecondary" style="text-align: right;"> ¿Cómo llegar?</h1>
                            <hr>
                        </div>
                        <p class="" style="text-align: right;">
                           Da click en el video para que veas lo fácil que es llegar a nuestras instalaciones.
                        </p>
                        <div class="">
                            <iframe width="560" height="315" [src]="video2 | safe" title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="row py-5 my-5 divSelect">
        <div class="col-xxl-11 col-xl-11 col-lg-11 col-md-12 col-12  ">
            <div class="container-fluid ">
                <div class="row">
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12  ">
                        <div class="row ">
                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 " *ngFor="let item of servicios">
                                <div class="card text-start m-1  "  style="position: relative;" id="cardPor">
                                    <img *ngIf="item.imagen!=null" class="card-img-top" [src]="urlBase+item.imagen+'?alt=media'"
                                        alt="Title" />
                                    <img *ngIf="item.imagen==null" class="card-img-top" src="../../../assets/img/imgDefault/sinProd.png"
                                        alt="Title" />
                                    <div class="card-body">
                                        <h6 class="card-title">{{item.nombre}}</h6>
                                        <p class="card-text" style="line-height: 1.25em; font-size: 13px; ">
                                            {{transformText(item.detalle)}}</p>
                                            <button 
                                                (click)="verDetalleServicio(item,modalDetalleServicio)"
                                                class="btn btn-sm  bgVerDetalle text-white"  title="VER DETALLE COMPLETO" style=" position: absolute; top: 0; right: 0;  " >
                                                <i class="bi bi-eye-fill"></i>
                                            </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                        <div class="row">
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-0"></div>
                            <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12">
                                <div class="card pt-5" style="border-color: transparent; ">
                                    <div class="divRigth2">
                                        <p class="nuestroServicios">
                                            {{information.detalleEmpresa}}
                                            <!-- <strong class="nameEmpresa2" [ngStyle]="{ 'color':botones[0].colorBoton }" >{{companyNane}}</strong> -->
                                            <strong class="nameEmpresa2 btnEmpresaColor "  >{{companyNane | uppercase}}</strong>
                                        </p>
                                        <p class="text-right " style="">
                                            {{information.detallefraseClave}}
                                        </p>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid  pb-5">
        <div class="row  ">
            <div class="col-12 ">
                <div class="card rowCard">
                    <div class="row mb-5">
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-12 ">
                            <!-- <img src="../../../assets/img/imgVM.png" alt="" class="imgMV" > -->
                            <div class="divCarMV divSelect " style="position: relative;">
                                <img *ngIf="information.imagenMision" [src]="urlBase+information.imagenMision+'?alt=media'" alt="" class="imgMV2 ">
                                <img *ngIf="!information.imagenMision"   src="../../../assets/img/imgDefault/historia.jpg" alt="" class="imgMV2 ">
                                <img *ngIf="information.imagenVision" [src]="urlBase+information.imagenVision +'?alt=media'" alt="" class="imgV"
                                    style="position: absolute; bottom: 0; right: 0;  box-shadow: 0px 0px 3px 10px #e3e3e3;  ">
                                <img *ngIf="!information.imagenVision"  src="../../../assets/img/imgDefault/sinContenido.png" alt="" class="imgV"
                                    style="position: absolute; bottom: 0; right: 0;  box-shadow: 0px 0px 3px 10px #e3e3e3; height: 50%;  ">
                            </div>
                        </div>
                        <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-6 col-12 ">
                            <div class="p-5 ">
                                <div class="card mt-5" style="background-color: transparent; border: none;">
                                    <div class="row ">
                                        <div class="col-12 pb-4">
                                            <span *ngIf="idShop==70" class="spanMV parrafoInfo"> <i class="bi bi-bullseye icoMV"></i> Misión:
                                                Eficiencia y Seguridad </span>
                                            <span class="spanMV parrafoInfo"> <i class="bi bi-bullseye icoMV"></i> Misión</span>
                                            <p class="pt-3 parrafoHistoria ">
                                                {{information.mision}}
                                            </p>
                                        </div>
                                        <div class="col-12 pb-4">
                                            <span *ngIf="idShop==70" class="spanMV parrafoInfo"> <i class="bi bi-eye icoMV"></i> Visión:
                                                Liderazgo en Mantenimiento </span>
                                            <span  class="spanMV parrafoInfo"> <i class="bi bi-eye icoMV"></i> Visión </span>
                                            <p class="pt-3 parrafoHistoria">
                                                {{information.vision}}
                                            </p>
                                        </div>
                                        <div class="col-12 ">
                                            <span *ngIf="idShop==70" class="spanMV parrafoInfo"> <i class="bi bi-gem icoMV"></i> Valores:
                                                Compromiso y Honestidad </span>
                                            <span class="spanMV parrafoInfo"> <i class="bi bi-gem icoMV"></i> Valores</span>
                                            <p class="pt-3 parrafoHistoria">
                                                {{information.valores}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-whats-app></app-whats-app>


<div #contacto>
    <app-footer *ngIf="configuracion" [informacion]="information" [configuracion]="configuracion"></app-footer>
</div>

<ng-template #modalDetalleServicio class="modal fade" data-backdrop="static" data-keyboard="false">
    <div class="row w-100 mt-2">
        <div class="col-12" style="display: flex; justify-content: end; ">
            <button style="display: none;" >
                
            </button>
            <button class="btn btn-sm rounded-circle btnClose  " (click)="closeModal()" >
                <i class="bi bi-x-lg"></i>
            </button>
        </div>
    </div>
    <div class="modal-body  ">

        <img [src]="urlBase+servicioDefault.imagen+'?alt=media'" class="rounded-2 " style="object-fit: contain;width: 100%; height: 33em; border-radius: 8px; "  alt="">
        <h2 class="textSecondary mb-2" >{{servicioDefault.nombre}}</h2>

        <h6 class=" mt-3" style="font-weight: 400;" >{{servicioDefault.detalle}}</h6>
    </div>
</ng-template>

<ng-template #modalPromo class="modal fade"  data-backdrop="static" data-keyboard="false">
    <div class="row w-100 mt-2">
        <div class="col-12" style="display: flex; justify-content: end; ">
            <button style="display: none;" >
                
            </button>
            <button class="btn btn-sm rounded-circle btnClose  " (click)="closeModal()" >
                <i class="bi bi-x-lg"></i>
            </button>
        </div>
    </div>

    <div class="modal-body  ">
        <img [src]="urlBase+promoDefault.url+'?alt=media'" class="rounded-2 " style="object-fit: contain;width: 100%; height: 33em; border-radius: 8px; "  alt="">
    </div>
</ng-template>

<ngx-loading [show]="loadingAll" [config]="{ backdropBorderRadius: '3px',  fullScreenBackdrop: true}" [template]="">
</ngx-loading>