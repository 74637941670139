<!-- Boton chat de whastapp -->
<div class="div-chat-whatsapp">
    <div class="text-right" >
        <i class="fab fa-whatsapp"></i>
        <span class="spn-info-whats text-size-14 pl-2 pr-2 pt-1 pb-1 rounded-pill text-light" role="button" (click)="contactWhatsapp(whatsappModal)">
            Contáctanos
            <i class="fas fa-chalkboard-teacher"></i>
        </span>
    </div>
</div>

<ng-template #whatsappModal let-modal class="modal-whatsapp" style="height: 20em;">

    <div class="modal-header header-whatsapp tipo-letra">
        <i class="fab fa-whatsapp icn-whatsapp"></i>
        <h6 class="modal-title title-whatsapp">Escríbenos a WhatsApp</h6>
        <button type="button" class="close text-white" aria-label="Close" (click)="modal.dismiss('Cross click')"
            >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body body-whatsapp tipo-letra">

        <div class="row cuadro-mensaje">
            <div class="col-12">
                <p class="p-mensaje">Hola, en que te podemos ayudar.</p>
            </div>
        </div>

        <div class="row" style="margin-top: 20px;">

            <div class="col-10">
                <input type="text" class="inp-mensaje" placeholder="Aquí tu mensaje ..." [(ngModel)]="textWhatsapp">
            </div>

            <div class="col-2 div-send">
                <div class="boton" (click)="modal.close('Close click')">
                    <a>
                        <i class="fas fa-angle-right"></i>
                    </a>
                </div>
            </div>

        </div>

    </div>

</ng-template>
