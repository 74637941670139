<nav class="navbar navbar-expand-lg  shadow-sm fixed-top" id="viewLG">
  <div class="container-fluid">
    <a class="navbar-brand" >
      <img  class="imgNavbar" [src]="urlBase+configuracion?.imgLogo+'?alt=media'" style="margin-left: 0.5em; ">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <!-- <li class="nav-item active"   >
          <a class="nav-link" (click)="redirecTO('inicio')"     [routerLink]="['/inicio']" routerLinkActive="router-link-active"
           >Inicio <span class="sr-only"></span></a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link" (click)="redirecTO('nosotros')" [routerLink]="['/inicio']" routerLinkActive="router-link-active" >Sobre Nosotros</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"   [routerLink]="['/home/1']" routerLinkActive="router-link-active">E-commerce</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"   [routerLink]="['/catalogo']" routerLinkActive="router-link-active">Catálogo</a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" (click)="redirecTO('contacto')"   [routerLink]="['/inicio']" routerLinkActive="router-link-active">Contáctanos</a>
        </li> -->

      </ul>
      <ul class="navbar-nav mr-auto " style="padding: 0; margin: 0;">
        <li class="mt-0" >
            <div class=" bg-light rounded rounded-pill shadow-sm  divSearch"  >
              <form  class="flex-form " [formGroup]="formSearchProduct">
                <div class="input-group mt-0">
                  <div class="input-group-prepend">
                      <button id="button-addon2" type="submit" class="btn btn-link text-secondary" (click)="searchProduct(formSearchProduct.value)">
                        <i class="fa fa-search"></i>
                      </button>
                  </div>
                  <input type="text" formControlName="text"  placeholder="¿Qué está buscando?"
                      aria-describedby="button-addon2" class="form-control border-0 bg-white rounded-pill">
              </div>
              </form>
            </div>
        </li>
      </ul>
      <form class="form-inline my-2 my-lg-0" *ngIf="flagCarLocal" >
        <div style="display: flex; justify-content: space-between; " >

          <div class="pt-1 divLogin " type="button" (click)="openModal(modalLogin)"  *ngIf="!clientLogin.login">
            <a class="nav-link"  >Iniciar Sesión</a>
          </div>
          <div class="div-auth pt-1" *ngIf="clientLogin.login"  >
            <i class="bi bi-box-arrow-right icoLogout"  type="button" title="SALIR" (click)="signOff()" ></i> 
            <span class="pr-3 linea-right-blanca spn-user-login" role="button" (click)="goClientProfile()">
               {{clientLogin.name | titlecase }}
            </span>
        </div>
          <div class="d-flex" style="height: 50px; background-color:rgb(133, 132, 132);  ">
            <div class="vr" style="width: 1px;"> </div>         
        </div>
          <div class="btn mt-1 mr-3" style="position: relative;"  type="button"  (click)="goShoppingCartUser()">
            <span style="position: absolute; top:0 ; right: 0; font-weight: 300; font-size: 10px;  "  class="badge badge-pill badge-danger">{{counterCar}}</span>
            <i class="bi bi-cart-fill p-1" style=" margin-top: 1em !important; " [ngStyle]="{ 'color': configuracion?.colorPrincipal }"> </i>  
          </div>
        </div> 
      </form>
    </div>
  </div>
</nav> 

<nav class="navbar navbar-expand-lg  shadow-sm fixed-top" id="viewSM" >
  <div class="container-fluid">
    <!-- <a class="navbar-brand" >
      <img  class="imgNavbar" [src]="urlBase+configuracion?.imgLogo+'?alt=media'" style="margin-left: 0.5em; ">

    </a> -->
    <form class="form-inline  " style="margin-left: 0.5em;">
      <div style="display: flex; justify-content: space-between; " >
  
        <div class="pt-1 divLogin " type="button" (click)="openModal(modalLogin)"  *ngIf="!clientLogin.login">
          <a class="nav-link"  >Iniciar Sesión</a>
        </div>
        <div class="div-auth pt-1" *ngIf="clientLogin.login"  >
          <i class="bi bi-box-arrow-right icoLogout"  type="button" title="SALIR" (click)="signOff()" ></i> 
          <span class="pr-3 linea-right-blanca spn-user-login" role="button" (click)="goClientProfile()">
             {{clientLogin.name | titlecase }}
          </span>
      </div>
        <div class="d-flex" style="height: 50px; background-color:rgb(133, 132, 132);  ">
          <div class="vr" style="width: 1px;"> </div>         
      </div>
        <div class="btn mt-1 mr-3" style="position: relative;"  type="button"  (click)="goShoppingCartUser()">
          <span style="position: absolute; top:0 ; right: 0; font-weight: 300; font-size: 10px;  "  class="badge badge-pill badge-danger">{{cartProducts.number}}</span>
          <i class="fas fa-shopping-cart" [ngStyle]="{ 'color': configuracion?.colorPrincipal }"
          style="width:6; height:6;"></i>    
        </div>
      </div> 
    </form>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active"   >
          <a class="nav-link" (click)="redirecTO('inicio')"     [routerLink]="['/inicio']" routerLinkActive="router-link-active"
           >Inicio <span class="sr-only"></span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="redirecTO('nosotros')" [routerLink]="['/inicio']" routerLinkActive="router-link-active" >Sobre Nosotros</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"  (click)="redirecTO2('nosotros')"   [routerLink]="['/home/1']" routerLinkActive="router-link-active">E-commerce</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"   [routerLink]="['/catalogo']" routerLinkActive="router-link-active">Catálogo</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="redirecTO('contacto')"   [routerLink]="['/inicio']" routerLinkActive="router-link-active">Contáctanos</a>
        </li>
      </ul>

    </div>

  </div>
  
</nav>


<!-- <nav class="navbar navbar-expand-lg  rounded-pill shadow fixed-top mt-3 " >
    <a class="navbar-brand p-2" >
      <img  class="" [src]="urlBase+configuracion?.imgLogo+'?alt=media'" style="width: 100%;height: 55px; object-fit: scale-down; ">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active"   >
          <a class="nav-link" (click)="redirecTO('inicio')"     [routerLink]="['/inicio']" routerLinkActive="router-link-active"
           >Inicio <span class="sr-only"></span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="redirecTO('nosotros')" [routerLink]="['/inicio']" routerLinkActive="router-link-active" >Sobre Nosotros</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"   [routerLink]="['/inicio']" routerLinkActive="router-link-active">E-commerce</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="redirecTO('contacto')"   [routerLink]="['/inicio']" routerLinkActive="router-link-active">Contáctanos</a>
        </li>
      </ul>
      <form class="form-inline my-2 my-lg-0">
        <div style="display: flex; justify-content: space-between; " >
          <div class="pt-1" type="button" (click)="openModal(modalLogin)"  *ngIf="!clientLogin.login">
            <a class="nav-link"  >Iniciar Sesión</a>
          </div>
          <div class="div-auth pt-1" *ngIf="clientLogin.login"  >
            <i class="bi bi-box-arrow-right icoLogout"  type="button" title="SALIR" (click)="signOff()" ></i> 
            <span class="pr-3 linea-right-blanca spn-user-login" role="button" (click)="goClientProfile()">
               {{clientLogin.name | titlecase }}
            </span>
        </div>
          <div class="d-flex" style="height: 50px; background-color:rgb(133, 132, 132);  ">
            <div class="vr" style="width: 1px;"> </div>         
        </div>
          <div class="btn mt-1 mr-3" style="position: relative;"  type="button"  (click)="goShoppingCartUser()">
            <span style="position: absolute; top:0 ; right: 0; font-weight: 300; font-size: 10px;  "  class="badge badge-pill badge-danger">{{cartProducts.number}}</span>
            <i class="fas fa-shopping-cart" [ngStyle]="{ 'color': configuracion?.colorPrincipal }"
            style="width:6; height:6;"></i>    
          </div>
        </div> 
      </form>
    </div>
</nav> -->

<ng-template #modalLogin class="modal fade" data-backdrop="static" data-keyboard="false">
    <app-login-form [configuracion]="configuracion" [information]="information"  (returnMsjForm)="openNewModal($event)"  ></app-login-form>
</ng-template>
